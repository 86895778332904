import auth0 from 'auth0-js';
import authconf from '../auth_config';
import { clearLocalStorage } from './Helpers';

export default class Auth {
    auth0 = new auth0.WebAuth({
        domain: authconf.domain,
        clientID: authconf.clientId,
        redirectUri: authconf.callback,
    });

    constructor() {


        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.getAccessToken = this.getAccessToken.bind(this);
        this.checkAccessToken = this.checkAccessToken.bind(this);
    }

    login() {
        this.auth0.authorize();
    }

    logout() {
        clearLocalStorage();
        var returnUrl = (window.location.protocol + '//' + window.location.host.replace(/\/$/, ""));
        this.auth0.logout({
            returnTo: returnUrl
        });        
        
        clearTimeout(this.tokenRenewalTimeout);

    }

    async checkAccessToken(){
        if(localStorage.getItem('access_token') && Date.now() >localStorage.getItem('expires_at')){
           return await this.auth0.client.userInfo(localStorage.getItem('access_token') ,(err, user) => {

                if(err) {
                    clearLocalStorage();
                    window.location = '/login';
            return false;
                }else{
                    return true;
                }
            });
        }else{
            return true;
        }
    }

    handleAuthentication() {

        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken) {

                this.setSession(authResult);

            } else if (err) {
                window.location.href = '/login';
            }
        });

    }

    isAuthenticated() {
        // Check whether the current time is past the
        // access token's expiry time
        let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }

    setSession(authResult) {
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('expires_at', expiresAt);
        this.auth0.client.userInfo(authResult.accessToken, (err, user) => {


            if (user) {
                localStorage.setItem('user', JSON.stringify(user));
                window.location = '/';
            } else {
                localStorage.removeItem('access_token');
                localStorage.removeItem('id_token');
                localStorage.removeItem('expires_at');
                localStorage.removeItem('user');
                window.location = '/login';

            }
        });
    }

    getUser() {
        if (localStorage.getItem('user')) {
            return JSON.parse(localStorage.getItem('user'));
        }
    }

    getUserName() {
        if (this.getUser()) {
            return this.getUser().name;
        }
    }

    getAccessToken() {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            throw new Error('No access token found');
        }
        return accessToken;
    }
}
