// this is a simple abstraction, that forwards the runtime configuration.
// _remotecheck_config is loaded at runtime.
// we do this, so that the existing code does not need to change.
module.exports = {
  "domain": window._remotecheck_config.domain,
  "clientId": window._remotecheck_config.clientId,
  "callback": window._remotecheck_config.callback,
  "realm": window._remotecheck_config.realm,
  "adminurl": window._remotecheck_config.adminurl,
  "duotone_baseurl": window._remotecheck_config.duotone_baseurl,
  "duotone_asseturl": window._remotecheck_config.duotone_asseturl,
  "signup_url": window._remotecheck_config.signup_url
}