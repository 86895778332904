import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import {Trans, withTranslation} from "react-i18next";

class TestMode extends Component {


    render() {

        return (
            <Typography variant="body1" className={'gray-box'} style={{marginBottom: 40}}>
                <strong> <Trans i18nKey="testMode.title" ></Trans></strong>
                <div style={{marginTop: 16}}>
                    <Trans i18nKey="testMode.text" >Text <strong className={'MuiTypography-colorPrimary'}></strong></Trans>
                </div>
            </Typography>)
    }
}

export default withTranslation()(TestMode);
