import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import logo from '../images/logo_neu.png';
import Button from '@material-ui/core/Button';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';
import MedelPaper from "../components/MedelPaper";
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Redirect} from 'react-router-dom'
import Auth from '../components/Auth';
import Slide from '@material-ui/core/Slide';
import authconf from '../auth_config';
import {interimSubmit, clearLocalStorage} from "../components/Helpers";
import StarRatings from 'react-star-ratings';
import {withTranslation, Trans} from 'react-i18next';
import {LanguageSelector} from '../components/LanguageSelector'
import moment from 'moment'

const auth = new Auth();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    space: {
        padding: theme.spacing(1.5, 3),
    },

    marginL: {
        marginRight: theme.spacing(1),
    },
});


function ElevationScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

class Dashboard extends Component {

    constructor(props) {
        super(props);


        this.state = {
            lingtest_left: false,
            duotonetest_left: false,
            trippletest_left: false,
            statuscheck_left: false,
            volume_left: false,
            rating: 0,
            lingtest_right: false,
            duotonetest_right: false,
            trippletest_right: false,
            statuscheck_right: false,
            volume_right: false,
            quest2test: false,
            all_left: false,
            all_right: false,
            all: false,
            textAreaValue: '',
            fotoupload: 0,
            name: false,
            left: false,
            logout: false,
            setOpen: false,
            setOpenInfo: localStorage.getItem('med-hide-notifictation') || localStorage.getItem('med-cancel-test') ? false : true,
            setOpenMessage: false,
            setOpenMessageCancel: localStorage.getItem('med-cancel-test') ? true : false,
            allFinished: false,
            transmitted: false,
            loaded: false,
            test: true,
            daysLeft: 0,
            leftEar: parseInt(localStorage.getItem('med-leftEar')) === 1,
            rightEar: parseInt(localStorage.getItem('med-rightEar')) === 1,
            curEar: localStorage.getItem('med-curEar') ? localStorage.getItem('med-curEar') : 'right',
            curName: (localStorage.getItem('med-curFirst') ? localStorage.getItem('med-curFirst') : '') + (localStorage.getItem('med-curLast') ? (localStorage.getItem('med-curFirst') ? ' ' : '') + localStorage.getItem('med-curLast') : ''),

        }

        const {t, i18n} = this.props;


    }


    msToDays(ms) {
        return Math.floor(ms / (24 * 60 * 60 * 1000));
    }

    handleClickOpen = () => {
        this.setState({...this.state, setOpen: true});
    };

    handleClose = () => {
        localStorage.setItem('med-hide-notifictation', true);
        this.setState({...this.state, setOpen: false, setOpenInfo: false});
    };

    changeRating = (newRating, name) => {

        this.setState({
            ...this.state,
            rating: newRating
        }, );
    }

    handleCookieClick = (e) => {
        e.preventDefault();
        document.getElementById('ot-sdk-btn').click();
    }

    async authuser() {


        try {

            const data = {
                action: 'authuser',
                medremtoken: localStorage.getItem('medel-token') ?? null,
                auth0id: this.user.sub ?? null,
                accessToken: localStorage.getItem('access_token') ?? null,
                timestamp: parseInt(Date.now() / 1000),
            }
            const response = await fetch(authconf.adminurl,
                {
                    method: "POST",
                    mode: 'cors',
                    body: JSON.stringify(data)
                }
            );

            const json = await response.json();


            if (json.test === 'yes') {
                let left = false;
                let right = false;
                localStorage.removeItem('med-leftEar');
                localStorage.removeItem('med-rightEar');

                for (let i = 0; i < json.side.length; i++) {

                    if (json.side[i] === 'left') {
                        left = true;
                        localStorage.setItem('med-leftEar', 1)
                    }
                    if (json.side[i] === 'right') {
                        right = true;
                        localStorage.setItem('med-rightEar', 1)

                    }
                }


                if (!localStorage.getItem('med-curEar')) localStorage.setItem('med-curEar', json.side[0]);


                if (json.firstname && json.firstname.length)
                    localStorage.setItem('med-curFirst', json.firstname);


                if (json.lastname && json.lastname.length)
                    localStorage.setItem('med-curLast', json.lastname);


                if (localStorage.getItem('medel-token')) {
                    localStorage.removeItem('medel-token');
                }


                if (typeof json.interim === 'object' && json.interim !== null) {
                    await this.restoreTest(json.interim);
                }

                if (typeof json.professional === 'object' && json.professional !== null) {
                    localStorage.setItem('med-curProf', JSON.stringify(json.professional));
                }


                this.setState({
                    test: (json.test === 'yes'),
                    duedate: parseInt(json.duedate) * 1000,
                    daysLeft: (parseInt(json.duedate) * 1000) - moment() + (24 * 60 * 60 * 1000),
                    curCheckup: json.curCheckup,
                    leftEar: left,
                    rightEar: right,
                    pasttest: json.pasttest,
                    professional: typeof json.professional === 'object' && json.professional !== null ? json.professional : null,
                    curName: ((json.firstname ? json.firstname : '') + (json.lastname ? (json.firstname ? ' ' : '') + json.lastname : '')),
                    curEar: localStorage.getItem('med-curEar')
                }, () => {
                    localStorage.setItem('med-dueDate', this.state.duedate);
                    localStorage.setItem('medel-daysExpired', this.state.daysLeft < 0);
                    localStorage.setItem('medel-daysLeft', this.msToDays(Math.abs(this.state.daysLeft)));
                });


            } else {
                this.setState({
                    test: false,
                });
            }


        } catch (error) {
            this.setState({
                test: false,
            });
        }


        return;

    }

    async componentDidMount() {


        auth.checkAccessToken();
        document.querySelector('body').classList.remove('login');
        this.user = JSON.parse(localStorage.getItem('user'));

        if (this.user) {
            this.user.name = (this.user && this.user.user_metadata && this.user.user_metadata.first_name) ? ((this.user.user_metadata.first_name ? this.user.user_metadata.first_name : '') + (this.user.user_metadata.last_name ? (this.user.user_metadata.first_name ? ' ' : '') + this.user.user_metadata.last_name : '')) : this.user.name;

            await this.authuser();
            this.setState({name: this.user.name});
            clearInterval(this.interval);
            this.setState({
                preparation: localStorage.getItem(this.user.sub + '-medel-preparation') ? true : false,
                lingtest_left: localStorage.getItem(this.user.sub + '-medel-ling_left') ? true : false,
                duotonetest_left: localStorage.getItem(this.user.sub + '-medel-duotone_left') ? true : false,
                trippletest_left: localStorage.getItem(this.user.sub + '-medel-tripple_left') ? true : false,
                statuscheck_left: localStorage.getItem(this.user.sub + '-medel-statuscheck_left') ? true : false,
                volume_left: localStorage.getItem(this.user.sub + '-medel-volume_left') ? true : false,
                quest2test: localStorage.getItem(this.user.sub + '-medel-quest2') ? true : false,
                fotoupload_left: parseInt(localStorage.getItem(this.user.sub + '-medel-fotoupload_left')) === 3 ? 3 : 0,
                lingtest_right: localStorage.getItem(this.user.sub + '-medel-ling_right') ? true : false,
                duotonetest_right: localStorage.getItem(this.user.sub + '-medel-duotone_right') ? true : false,
                trippletest_right: localStorage.getItem(this.user.sub + '-medel-tripple_right') ? true : false,
                statuscheck_right: localStorage.getItem(this.user.sub + '-medel-statuscheck_right') ? true : false,
                volume_right: localStorage.getItem(this.user.sub + '-medel-volume_right') ? true : false,
                fotoupload_right: parseInt(localStorage.getItem(this.user.sub + '-medel-fotoupload_right')) === 3 ? 3 : 0
            }, () => {
                if ((this.state.lingtest_left && this.state.duotonetest_left && this.state.trippletest_left && this.state.volume_left && this.state.quest2test && this.state.fotoupload_left) || this.state.leftEar === false) {


                    this.setState({all_left: true}, () => {

                    });
                }
                if ((this.state.lingtest_right && this.state.duotonetest_right && this.state.trippletest_right && this.state.statuscheck_right && this.state.quest2test && this.state.fotoupload_right) || this.state.rightEar === false) {

                    this.setState({all_right: true}, () => {

                        setTimeout(() => {
                            if (this.state.all_right === true && this.state.all_left === true) {
                                this.setState({all: true}, () => {
                                });
                            } else {
                                this.setState({all: false}, () => {
                                });

                            }

                        }, 500)
                    });
                }
            });
        } else {

            this.interval = window.setInterval(async () => {
                this.user = JSON.parse(localStorage.getItem('user'));

                if (this.user) {
                    this.user.name = (this.user && this.user.user_metadata && this.user.user_metadata.first_name) ? ((this.user.user_metadata.first_name ? this.user.user_metadata.first_name : '') + (this.user.user_metadata.last_name ? (this.user.user_metadata.first_name ? ' ' : '') + this.user.user_metadata.last_name : '')) : this.user.name;

                    await this.authuser();
                    this.setState({...this.state, name: this.user.name});


                    clearInterval(this.interval);
                }
            }, 1000);

        }

        this.setState({loaded: true})


    }

    restoreTest = async (interim) => {

        if (interim.left.preparation) {
            await localStorage.setItem(this.user.sub + '-medel-preparation', interim.preparation);
        }
        if (interim.left.lingtest) {
            await localStorage.setItem(this.user.sub + '-medel-ling_left', interim.left.lingtest);
        }
        if (interim.left.duotonetest) {
            await localStorage.setItem(this.user.sub + '-medel-duotone_left', interim.left.duotonetest);
        }
        if (interim.left.trippletest) {
            await localStorage.setItem(this.user.sub + '-medel-tripple_left', interim.left.trippletest);
        }
        if (interim.left.statuscheck) {
            await localStorage.setItem(this.user.sub + '-medel-statuscheck_left', interim.left.statuscheck);
        }
        if (interim.left.volume) {
            await localStorage.setItem(this.user.sub + '-medel-volume_left', interim.left.volume);
        }
        if (interim.left.volume_patient_start_test) {
            await localStorage.setItem(this.user.sub + '-medel-volume-patient_start_test_left', interim.left.volume_patient_start_test);
        }
        if (interim.left.volume_patient_end_test) {
            await localStorage.setItem(this.user.sub + '-medel-volume-patient_end_test_left', interim.left.volume_patient_end_test);
        }
        if (interim.left.quest2test) {
            await localStorage.setItem(this.user.sub + '-medel-quest2', interim.left.quest2test);
        }

        if (interim.left.fotoupload && interim.left.fotoupload.image1 && interim.left.fotoupload.image2 && interim.left.fotoupload.image3) {
            await localStorage.setItem(this.user.sub + '-medel-fotoupload_left', 3);
            if (interim.left.fotoupload && interim.left.fotoupload.image1) {
                await localStorage.setItem(this.user.sub + '-medel-img-1_left', interim.left.fotoupload.image1);
            }
            if (interim.left.fotoupload && interim.left.fotoupload.image2) {
                await localStorage.setItem(this.user.sub + '-medel-img-2_left', interim.left.fotoupload.image2);
            }
            if (interim.left.fotoupload && interim.left.fotoupload.image3) {
                await localStorage.setItem(this.user.sub + '-medel-img-3_left', interim.left.fotoupload.image3);
            }

            if (interim.left.fotoupload && interim.left.fotoupload.log) {
                await localStorage.setItem(this.user.sub + '-medel-fotoupload-log_left', interim.left.fotoupload.log);
            }
        }


        if (interim.left.fotoupload && interim.left.fotoupload.image1 && interim.left.fotoupload.image2 && interim.left.fotoupload.image3) {
            await localStorage.setItem(this.user.sub + '-medel-fotoupload_left', 3);
        }
        if (interim.right.preparation) {
            await localStorage.setItem(this.user.sub + '-medel-preparation', interim.preparation);
        }
        if (interim.right.lingtest) {
            await localStorage.setItem(this.user.sub + '-medel-ling_right', interim.right.lingtest);
        }
        if (interim.right.duotonetest) {
            await localStorage.setItem(this.user.sub + '-medel-duotone_right', interim.right.duotonetest);
        }
        if (interim.right.trippletest) {
            await localStorage.setItem(this.user.sub + '-medel-tripple_right', interim.right.trippletest);
        }
        if (interim.right.statuscheck) {
            await localStorage.setItem(this.user.sub + '-medel-statuscheck_right', interim.right.statuscheck);
        }
        if (interim.right.quest2test) {
            await localStorage.setItem(this.user.sub + '-medel-quest2', interim.right.quest2test);
        }

        if (interim.right.volume) {
            await localStorage.setItem(this.user.sub + '-medel-volume_right', interim.right.volume);
        }
        if (interim.right.volume_patient_start_test) {
            await localStorage.setItem(this.user.sub + '-medel-volume-patient_start_test_right', interim.right.volume_patient_start_test);
        }
        if (interim.right.volume_patient_end_test) {
            await localStorage.setItem(this.user.sub + '-medel-volume-patient_end_test_right', interim.right.volume_patient_end_test);
        }

        if (interim.right.fotoupload && interim.right.fotoupload.image1 && interim.right.fotoupload.image2 && interim.right.fotoupload.image3) {
            await localStorage.setItem(this.user.sub + '-medel-fotoupload_right', 3);
            if (interim.right.fotoupload && interim.right.fotoupload.image1) {
                await localStorage.setItem(this.user.sub + '-medel-img-1_right', interim.right.fotoupload.image1);
            }
            if (interim.right.fotoupload && interim.right.fotoupload.image2) {
                await localStorage.setItem(this.user.sub + '-medel-img-2_right', interim.right.fotoupload.image2);
            }
            if (interim.right.fotoupload && interim.right.fotoupload.image3) {
                await localStorage.setItem(this.user.sub + '-medel-img-3_right', interim.right.fotoupload.image3);
            }

            if (interim.right.fotoupload && interim.right.fotoupload.log) {
                await localStorage.setItem(this.user.sub + '-medel-fotoupload-log_right', interim.right.fotoupload.log);
            }
        }


    };
    switchEar = (e, side) => {
        e.preventDefault();
        localStorage.setItem('med-curEar', side);
        this.setState({curEar: side});
    };

    ratingHTML = () => {
        const {t} = this.props;
        return (<div style={{
            width: '100%',
            marginTop: '0',
            padding: '20px',
            textAlign: 'center',
            fontWeight: '600'
        }}>
            {t('howWouldYouRate')}
            <div style={{width: 'auto', margin: '10px auto', marginBottom: '0'}}>

                <StarRatings
                    rating={this.state.rating}
                    starRatedColor="rgb(198,12,48)"
                    starHoverColor="rgb(198,12,48)"
                    starEmptyColor="#D1D4D3"
                    starDimension={'25px'}
                    changeRating={this.changeRating}
                    numberOfStars={5}
                    starSpacing={'5px'}
                    name='rating'
                />
            </div>
        </div>)
    };

    handleOnTextChange = (event) => {
        this.pMessage = event.target.value;
        this.setState({
            textareaValue: event.target.value
        },)
    };


    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({...this.state, [side]: open});
    };

    async handleTransmit(cancel) {

        let arrayLeft = {}, arrayRight = {};

        let side = [];


        if (this.state.leftEar) {

            if (this.state.leftEar === true) side.push('left');
            arrayLeft = {
                lingtest: localStorage.getItem(this.user.sub + '-medel-ling_left'),
                duotonetest: localStorage.getItem(this.user.sub + '-medel-duotone_left'),
                trippletest: localStorage.getItem(this.user.sub + '-medel-tripple_left'),
                statuscheck: localStorage.getItem(this.user.sub + '-medel-statuscheck_left'),
                quest2test: localStorage.getItem(this.user.sub + '-medel-quest2'),
                fotoupload: {
                    image1: localStorage.getItem(this.user.sub + '-medel-img-1_left'),
                    image2: localStorage.getItem(this.user.sub + '-medel-img-2_left'),
                    image3: localStorage.getItem(this.user.sub + '-medel-img-3_left'),
                    log: localStorage.getItem(this.user.sub + '-medel-fotoupload-log_left'),
                },
                volume: localStorage.getItem(this.user.sub + '-medel-volume_left'),
                volume_patient_end_test: localStorage.getItem(this.user.sub + '-medel-volume-patient_end_test_left'),
                volume_patient_start_test: localStorage.getItem(this.user.sub + '-medel-volume-patient_start_test_left'),
            }
        }

        if (this.state.rightEar) {
            if (this.state.rightEar === true) side.push('right');
            arrayRight = {
                lingtest: localStorage.getItem(this.user.sub + '-medel-ling_right'),
                duotonetest: localStorage.getItem(this.user.sub + '-medel-duotone_right'),
                trippletest: localStorage.getItem(this.user.sub + '-medel-tripple_right'),
                statuscheck: localStorage.getItem(this.user.sub + '-medel-statuscheck_right'),
                quest2test: localStorage.getItem(this.user.sub + '-medel-quest2'),
                fotoupload: {
                    image1: localStorage.getItem(this.user.sub + '-medel-img-1_right'),
                    image2: localStorage.getItem(this.user.sub + '-medel-img-2_right'),
                    image3: localStorage.getItem(this.user.sub + '-medel-img-3_right'),
                    log: localStorage.getItem(this.user.sub + '-medel-fotoupload-log_right'),
                },
                volume: localStorage.getItem(this.user.sub + '-medel-volume_right'),
                volume_patient_end_test: localStorage.getItem(this.user.sub + '-medel-volume-patient_end_test_right'),
                volume_patient_start_test: localStorage.getItem(this.user.sub + '-medel-volume-patient_start_test_right'),
            }
        } else {
            arrayRight = arrayLeft;
        }

        if (!this.state.leftEar) {
            arrayLeft = arrayRight;
        }

        if (cancel) {
            arrayLeft = false;
            arrayRight = false;

            if (localStorage.getItem('med-cancel-test')) {
                if (side.indexOf("left") !== -1) {
                    arrayLeft = {
                        statuscheck: localStorage.getItem(this.user.sub + '-medel-statuscheck_left')
                    };
                }

                if (side.indexOf("right") !== -1) {
                    arrayRight = {
                        statuscheck: localStorage.getItem(this.user.sub + '-medel-statuscheck_right')
                    };
                }
            }
        }


        this.finalarray = {
            auth0id: this.user.sub,
            accessToken: localStorage.getItem('access_token'),
            mode: 'submitTest',
            cancel: cancel ? true : false,
            left: arrayLeft,
            right: arrayRight,
            side: side,
            preparation: localStorage.getItem(this.user.sub + '-medel-preparation'),
            rating: this.state.rating,
            patient_end_test: parseInt(Date.now() / 1000),
            message: this.state.textareaValue
        };

        try {
            const response = await fetch(authconf.adminurl, {
                method: 'post',
                mode: 'cors',
                body: JSON.stringify(this.finalarray)
            });

            const json = await response.json();

            if (json.success) {
                this.setState({...this.state, transmitted: true}, () => {
                    clearLocalStorage();

                })
            }

        } catch (error) {
        }
    };


    sideList = side => {
        const {t} = this.props;
        return (
            <div
                className={'menu'}
                role="presentation"
                onClick={this.toggleDrawer(side, false)}
                onKeyDown={this.toggleDrawer(side, false)}
            >
                <List>
                    <div>
                        <ListItem button key={this.props.t('dashboardMenu.overview')} onClick={() => {
                            window.location.href = '/';
                        }}>
                            <ListItemText className={'active-menu'} primary={this.props.t('dashboardMenu.overview')}/>
                        </ListItem>
                        <Divider/>
                    </div>
                    <div>
                        <ListItem button key={this.props.t('dashboardMenu.help')} onClick={() => {
                            window.location.href = '/help';
                        }}>
                            <ListItemText primary={this.props.t('dashboardMenu.help')}/>
                        </ListItem>
                        <Divider/>
                    </div>
                    <div>
                        <ListItem button key={this.props.t('dashboardMenu.version')} onClick={() => {
                            window.location.href = '/version';
                        }}>
                            <ListItemText primary={this.props.t('dashboardMenu.version')}/>
                        </ListItem>
                        <Divider/>
                    </div>
                    <div>
                        <ListItem button key={this.props.t('dashboardMenu.logout')} onClick={() => {
                            auth.logout();
                        }}>
                            <ListItemText primary={this.props.t('dashboardMenu.logout')}/>
                        </ListItem>
                        <Divider/>
                    </div>

                </List>
                <div className={'bottom'}>
                    <a href={t('termsLink')} target={'_blank'} style={{display :'block'}}>{t('termsLinktext')}</a>



                    <a href={t('privacyLink')} target={'_blank'} style={{display :'block' , marginBottom: 20}}>{t('privacyLinktext')}</a>
                    <strong><a onClick={this.handleCookieClick} href={'#'}
                               className={'NavLink cookieref'}>{this.props.t('dashboardMenu.cookieSettings')}</a>
                    </strong>
                </div>
            </div>
        )
    };


    render() {
        const {classes, t} = this.props;
        let {duedate, daysLeft} = this.state;
        duedate = moment(duedate).format(t('dateformat'));
        let daysLeftRender = '';
        let daysLeftIntro = '';

        if (daysLeft < 0) {
            daysLeft = this.msToDays(Math.abs(daysLeft));
            daysLeftRender = (<div className={'daysLeft'}>
                <div>{t('reminderDialog.prevDaysExpired')}</div>
                <div><strong>{daysLeft} {t('reminderDialog.daysExpired')}</strong></div>
                <div>{t('reminderDialog.afterDaysExpired')}</div>
            </div>)

            daysLeftIntro = (<Trans i18nKey="preparation.textExpired" daysLeft={daysLeft}>{{daysLeft}}</Trans>)
        } else {
            daysLeft = this.msToDays(Math.abs(daysLeft));
            daysLeftRender = (<div className={'daysLeft'}>
                <div>{t('reminderDialog.prevDays')}</div>
                <div><strong>{daysLeft} {t('reminderDialog.days')}</strong></div>
                <div>{t('reminderDialog.afterDays')}</div>
            </div>)
            daysLeftIntro = (<Trans i18nKey="preparation.text" daysLeft={daysLeft}>{{daysLeft}}</Trans>)

        }


        if (this.state.logout === true) {
            return (<Redirect to={{pathname: '/login', state: {from: '/dashboard '}}}/>)
        } else if (this.state.loaded && this.user && this.state.test === false) {
            return (<div className={classes.root + ' ' + 'Dashboard'}>
                <AppBar color="default" fixed='true'>
                    <Toolbar>
                        <IconButton edge="start" className={classes.menuButton} color="primary"
                                    aria-label="menu" onClick={this.toggleDrawer('left', true)}>
                            <MenuIcon/>
                        </IconButton>
                        <LanguageSelector/>
                        <Typography variant="h6" className={classes.title}>
                        </Typography>
                        <a href='/' className={'logo'} style={{marginLeft: "15px"}}><img alt=''
                                                                                         src={logo}/></a>
                    </Toolbar>
                </AppBar>
                <Toolbar/>
                <div className={'intro-content'}  >
                    <Container maxWidth='sm'>
                        <div className={'header'}>
                            <Typography variant="body2">{t('welcome')}</Typography>
                            <Typography variant="h6" gutterBottom style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}>{this.state.curName.length ? this.state.curName : this.state.name}</Typography>
                        </div>
                    </Container>
                </div>
                <Container maxWidth='sm' className={'dashboard-content no-plan'} style={{marginTop: 40}}>
                    <Typography variant="body1" className={'content'}
                                style={{marginBottom: 15, marginTop: 15, fontWeight: 600}}>
                        {t('noPlannedTestsMessage')}
                    </Typography>
                </Container>


                <SwipeableDrawer
                    open={this.state.left}
                    onClose={this.toggleDrawer('left', false)}
                    onOpen={this.toggleDrawer('left', true)}
                >
                    <IconButton className={'menu-close close-icon'} color="primary"
                                aria-label="menu" data-closetext={t('closeLabel')} onClick={() => {
                        this.setState({...this.state, left: false});
                    }}>
                        <CloseIcon/>
                    </IconButton>
                    {this.sideList('left')}
                </SwipeableDrawer>
            </div>)
        } else {
            if (this.state.loaded && this.user && this.state.allFinished !== true) {

                const cancelQuestion = localStorage.getItem('med-cancel-question');
                const cancelAnswer = localStorage.getItem('med-cancel-value');

                return (

                    <div className={classes.root + ' Dashboard'}>


                        <AppBar color="default" fixed='true'>
                            <Toolbar>
                                <IconButton edge="start" className={classes.menuButton} color="primary"
                                            aria-label="menu" onClick={this.toggleDrawer('left', true)}>
                                    <MenuIcon/>
                                </IconButton>
                                <LanguageSelector/>
                                <Typography variant="h6" className={classes.title}>

                                </Typography>

                                <a href='/' className={'logo'} style={{marginLeft: "15px"}}><img
                                    alt='' src={logo}/></a>
                            </Toolbar>
                        </AppBar>
                        <Toolbar/>
                        <div className={'intro-content'}  >
                        <Container maxWidth='sm'>
                                <div className={'header'}>
                                    <Typography variant="h6" gutterBottom
                                                style={{}}> {t('welcome')} {this.state.curName.length ? this.state.curName : this.state.name}!</Typography>
                                    {daysLeftIntro}
                                </div>
                            </Container>
                        </div>

                        <div className={'intro-content border'}>
                            <Container maxWidth='sm'>
                                <strong className={'testLabel'}>{t('preparationTest.testLabel')}</strong>
                                <MedelPaper title={t('preparationTest.title')} info={t('preparationTest.timeInfo')}
                                            active={this.state['preparation']}
                                            link={'preparation'}/>
                            </Container>
                        </div>
                        <Container maxWidth='sm' className={'dashboard-content checkup-tabs-outer'}>


                            <div className={'checkup-tabs'}>

                                {this.state.leftEar === true ?
                                    <a href={'#'}
                                       className={localStorage.getItem('med-curEar') === 'left' ? 'active' : ''}
                                       onClick={(e) => this.switchEar(e, 'left')}><span>{t('leftEar')}</span></a> : ''}
                                {this.state.rightEar === true ?
                                    <a href={'#'}
                                       className={localStorage.getItem('med-curEar') === 'right' ? 'active' : ''}
                                       onClick={(e) => this.switchEar(e, 'right')}><span>{t('rightEar')}</span></a> : ''}
                            </div>
                        </Container>
                        <div className={'intro-content border no-top'}>
                            <Container maxWidth='sm'>
                                <div className={'checkup-papers'}>
                                    <strong className={'testLabel'}>{t('myProcessor.testLabel')}</strong>
                                    <MedelPaper title={t('myProcessor.title')} info={t('myProcessor.timeInfo')}
                                                active={this.state['statuscheck_' + this.state.curEar]}
                                                hide={!this.state['preparation']}
                                                link={'statustest'}/>
                                    <strong className={'testLabel'}>{t('setVolume.testLabel')}</strong>
                                    <MedelPaper title={t('setVolume.title')} info={t('setVolume.timeInfo')}
                                                icon={'sound'}
                                                active={this.state['volume_' + this.state.curEar]}
                                                hide={!this.state['statuscheck_' + this.state.curEar] || (!this.state['preparation'])}
                                                link={'volume'}/>
                                    <MedelPaper title={t('lingTest.title')} info={t('lingTest.timeInfo')} icon={'ear'}
                                                active={this.state['lingtest_' + this.state.curEar]}
                                                hide={(!this.state['volume_' + this.state.curEar]) || (!this.state['preparation'])}
                                                link={'lingtest'}/>
                                    <MedelPaper title={t('threeDigitTest.title')} info={t('threeDigitTest.timeInfo')}
                                                icon={'ear'}
                                                active={this.state['trippletest_' + this.state.curEar]}
                                                hide={(!this.state['volume_' + this.state.curEar]) || (!this.state['preparation'])}
                                                link={'trippletest'}/>
                                    <MedelPaper title={t('duotoneTest.title')} info={t('duotoneTest.timeInfo')}
                                                icon={'ear'}
                                                active={this.state['duotonetest_' + this.state.curEar]}
                                                hide={(!this.state['volume_' + this.state.curEar]) || (!this.state['preparation'])}
                                                link={'duotonetest'}/>
                                    <strong className={'testLabel'}>{t('earSelfie.testLabel')}</strong>
                                    <MedelPaper title={t('earSelfie.title')} icon={'camera'}
                                                info={(this.state['fotoupload_' + this.state.curEar] === 3 ? 3 : 0) + ' / 3'}
                                                active={(this.state['fotoupload_' + this.state.curEar] === 3)}
                                                hide={(!this.state['volume_' + this.state.curEar]) || (!this.state['preparation'])}
                                                link={'photoupload'}/>
                                </div>

                                <div className={'checkup-papers seperate'}>
                                    <strong className={'testLabel'}>{t('myListeningComprehension.testLabel')}</strong>
                                    <MedelPaper title={t('myListeningComprehension.title')}
                                                info={t('myListeningComprehension.timeInfo')}
                                                active={this.state.quest2test}
                                                hide={((this.state.rightEar === true && this.state.leftEar === true) ? !(this.state['statuscheck_left'] && this.state['statuscheck_right']) : !this.state['statuscheck_' + this.state.curEar]) || (!this.state['preparation'])}
                                                link={'quest2test'}/>
                                </div>

                                <div className={'button-send'}>
                                    {this.state.all === false ? <Button fullWidth variant="contained"
                                                                        className={classes.space} onClick={() => {
                                        this.setState({...this.state, setOpenMessageCancel: true}, () => {


                                            document.body.classList.add("no-orientation");
                                        })
                                    }}>
                                        {t('cancelTest')}
                                    </Button> : <Button fullWidth variant="contained" color="primary"
                                                        disabled={this.state.all === false}
                                                        className={classes.space} onClick={() => {
                                        this.setState({...this.state, setOpenMessage: true}, () => {
                                            document.body.classList.add("no-orientation");
                                        })
                                    }}>
                                        {t('send')}
                                    </Button>}
                                </div>

                            </Container>
                        </div>
                        <SwipeableDrawer
                            open={this.state.left}
                            onClose={this.toggleDrawer('left', false)}
                            onOpen={this.toggleDrawer('left', true)}
                        >
                            <IconButton className={'menu-close close-icon '} color="primary"
                                        aria-label="menu" data-closetext={t('closeLabel')} onClick={() => {
                                this.setState({...this.state, left: false});
                            }}>
                                <CloseIcon/>
                            </IconButton>
                            {this.sideList('left')}
                        </SwipeableDrawer>


                        <Dialog
                            open={this.state.setOpenMessage}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle
                                id="alert-dialog-slide-title"> {t('submitTestResultDialog.title')}</DialogTitle>
                            <DialogContent>
                                {t('submitTestResultDialog.infoMessage')}<br/><br/>
                                <textarea placeholder={t('submitTestResultDialog.infoMessagePlaceholder')}
                                          onChange={(event) => this.handleOnTextChange(event)} style={{
                                    border: '1px solid #D1D4D3',
                                    padding: 5,
                                    width: '100%',
                                    minHeight: 150,
                                    marginTop: '20px',
                                    fontSize: '0.875rem'
                                }}></textarea>
                                {this.ratingHTML()}
                                <Button variant={'contained'} className={'gray block'} onClick={() => {
                                    this.setState({setOpenMessage: false}, () => {
                                        document.body.classList.remove("no-orientation");
                                    })
                                }} color={'primary'}>
                                    {t('submitTestResultDialog.cancel')}
                                </Button>
                                <Button variant={'contained'} className={'block'} onClick={() => {
                                    this.setState({allFinished: true}, this.handleTransmit)
                                }} color="primary">
                                    {t('submitTestResultDialog.send')}
                                </Button>
                            </DialogContent>

                        </Dialog>

                        <Dialog
                            open={this.state.setOpenMessageCancel}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={localStorage.getItem('med-cancel-test') ? () => {
                            } : this.handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle
                                id="alert-dialog-slide-title">
                                <img src={'/img/attention_small.svg'} style={{width:'30%', display: 'block', marginBottom: '20px'}}/>
                                {localStorage.getItem('med-cancel-test') ? t('cancelTestDialog.title') : t('cancelTestDialog.titlePreCancel')}
                            </DialogTitle>
                            <DialogContent>
                                {localStorage.getItem('med-cancel-test') ?
                                    <span><Trans i18nKey="cancelTestDialog.cannotCompleteTest"
                                                 cancelQuestion={cancelQuestion} cancelAnswer={cancelAnswer}><em>{{cancelQuestion}}</em><strong>{{cancelAnswer}}</strong></Trans></span> : t('cancelTestDialog.manualCancelText')}

                                <textarea placeholder={t('cancelTestDialog.yourMessage')}
                                          onChange={(event) => this.handleOnTextChange(event)} style={{
                                    border: 'none',
                                    color: '#000',
                                    backgroundColor: '#E5E5E5',
                                    padding: 10,
                                    width: '100%',
                                    minHeight: 80,
                                    fontSize: '0.875rem',
                                    marginTop: '20px',
                                }}></textarea>

                                {this.ratingHTML()}
                                <Button variant={'contained'}  onClick={() => {
                                    if (!this.state.textareaValue || this.state.textareaValue.length < 5) {
                                        alert(t('cancelTestDialog.cancelReasonRequiredMessage'));
                                    } else {
                                        this.setState({allCanceled: true, allFinished: true}, () => {
                                            this.handleTransmit(true)
                                        })
                                    }
                                }} color="primary" className={'block red'}>
                                    {t('cancelTestDialog.yesCancel')}
                                </Button>

                                {<Button variant={'contained'}  onClick={async () => {
                                    let tmpState = {
                                        setOpenMessageCancel: false
                                    };
                                    document.body.classList.remove("no-orientation");

                                    if (localStorage.getItem('med-cancel-test')) {
                                        tmpState['statuscheck_' + this.state.curEar] = false;

                                        await localStorage.removeItem(this.user.sub + '-medel-statuscheck' + ('_' + this.state.curEar));
                                        await localStorage.removeItem('med-cancel-test');

                                    }

                                    this.setState(tmpState);
                                    let resp = await interimSubmit();
                                }} color="primary" className={'block '}>
                                    {t('cancelTestDialog.noBack')}
                                </Button>
                                }


                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={this.state.setOpenInfo}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle
                                id="alert-dialog-slide-title">{t('reminderDialog.title')}</DialogTitle>
                            <DialogContent>
                                <img alt='' src={'/img/icons/sanduhr.png'}/>
                                {daysLeftRender}
                                <DialogContentText id="alert-dialog-slide-description">
                                    <Trans i18nKey="reminderDialog.message" dueDate={duedate}>Your doctor plans
                                        remote checks for you. Please finish
                                        until <strong>{{dueDate: duedate}}</strong>.</Trans>

                                    <div style={{textAlign: 'center', marginTop: '25px'}}>
                                        <Button onClick={this.handleClose} color="primary">
                                            {t('reminderDialog.confirm')}
                                        </Button>
                                    </div>
                                </DialogContentText>
                            </DialogContent>

                        </Dialog>
                    </div>

                );
            } else if (this.state.loaded && this.user && this.state.allFinished === true) {
                return (<Container className={'finish-screen ' + (this.state.transmitted ? 'finished' : '')}>
                    <div className={'load-circle'}>
                        <CircularProgress/>
                        <Typography>{this.state.allCanceled ? t('finishScreen.testCancellationInProgressMessage') : t('finishScreen.testSubmissionInProgressMessage')}</Typography>
                    </div>
                    <div className={'success-message'}>
                        <div className={'header'}>
                            <div>
                                <div className={'arrow'}>
                                    <svg viewBox="0 0 136.3 103"  width="136px" height="103px">
                                        <polyline points="10.7,44.6 50.9,84.5 125.6,10.5 "></polyline>
                                        <polyline points="11.6,45.6 50.9,84.5 124.6,11.5 "></polyline>
                                    </svg>
                                </div>
                                <Typography variant="h4" style={{marginTop: 45}}>
                                    {this.state.allCanceled ? t('finishScreen.testCancellationFinishedMessage') : t('finishScreen.testSubmissionFinishedMessage')}
                                </Typography>
                                <Typography>
                                    {t('finishScreen.closeMessage')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Container>);
            } else {
                return (<Container>
                    <div className={'load-circle'}>
                        <CircularProgress/>
                    </div>
                </Container>);
            }
        }
    }
}

export default withTranslation()(withStyles(styles)(Dashboard));
