import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {default as ButtonLink} from '@material-ui/core/Link';

import Webcam from "react-webcam";
import Button from '@material-ui/core/Button';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom'
import BackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import SwitchIcon from '@material-ui/icons/SwitchCamera';
import SwitchCameraOutlinedIcon from '@material-ui/icons/SwitchCameraOutlined';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import TimerIcon from '@material-ui/icons/Timer';

import "p5/lib/addons/p5.sound";
import CircularProgress from '@material-ui/core/CircularProgress';
import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import video1 from '../images/medel_video.mp4';
import Background1 from '../images/earselfie1.png';
import Background2 from '../images/earselfie2.png';
import Background3 from '../images/earselfie3.png';
import authconf from "../auth_config";
import {Link as mLink} from '@material-ui/icons/Link';

import soundfile from '../sounds/shutter.mp3';
import soundfile2 from '../sounds/beeplong.wav';
import {interimSubmit, redirectToHome} from "../components/Helpers";
import Auth from "../components/Auth";
import {withTranslation, Trans} from 'react-i18next';

const auth = new Auth();

var posterStyle1 = {
    backgroundImage: "url(" + Background1 + ")"
};


var posterStyle2 = {
    backgroundImage: "url(" + Background2 + ")"
};


var posterStyle3 = {
    backgroundImage: "url(" + Background3 + ")"
};


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textAlign: 'center'
    },
    space: {
        padding: theme.spacing(1.5, 3),
    },

    marginL: {
        marginRight: theme.spacing(1),
    },
});


function ElevationScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};
let songsArray = [];


class PhotoUpload extends Component {

    constructor(props) {
        super(props);


        //EDIT HERE
        this.rounds = 3;

        this.index = 0;
        this.successsound = false;
        this.unmount = false;
        this.totalAnswers = this.rounds * 6;
        this.answerGiven = 0;
        this.user = JSON.parse(localStorage.getItem('user'));
        this.state = {
            preview: false,
            startTest: false,
            finishedTest: false,
            curImage: '',
            setOpen: false,
            isLoading: false,
            fotoCount: 0,
            totalFotos: 3,
            timerOn: false,
            firstTimerInfo: true,
            timerInfo: false,
            cameraInfo: false,
            dialogInfo: false,
            facingMode: 'user'
        };


        const {t, i18n} = this.props;


    }

    updatePerc = () => {
        let perc = this.state.fotoCount / this.state.totalFotos;
        document.getElementById('percentage').style.width = ((100 * (perc))) + '%';
        this.state.percentage = Math.round(perc * 100);
        this.setState({percentage: this.state.percentage});
    }

    componentWillUnmount() {
        this.unmount = true;
        clearTimeout(this.timeout);
        clearInterval(this.interval);

        songsArray.map((elem, i) => {
            if (songsArray[i].sound.isPlaying()) {
                songsArray[i].sound.stop();
                songsArray[i].sound.clearCues();
            }

            delete songsArray[i].sound;
            delete songsArray[i];

            return true;
        })
    }

    handleClickOpen = () => {
        this.setState({...this.state, setOpen: true});
    }

    handleClose = () => {
        this.setState({...this.state, setOpen: false});

    }

    handleCloseInfo = () => {
        this.setState({...this.state, dialogInfo: false}, () => {
            if (this.state.startTest === false) {
                window.setTimeout(() => {
                    this.setState({...this.state, fotoCount: 0});
                }, 350)
            }
        });
    }

    handleCloseTimer = () => {
        this.setState({...this.state, timerInfo: false, firstTimerInfo: false}, () => {

        });
    }

    onTakePhoto = () => {

        if (this.state.isCountdown) return;

        if (!this.state.timerOn) {
            this.takePhoto();
        } else {
            let countdown = 5;
            document.getElementById('timer-overlay').style.display = 'flex';
            var beep = document.getElementById('beep');
            beep.volume = 0.1;
            beep.play();
            this.setState({isCountdown: true}, () => {
                let countdownInterval = window.setInterval(() => {
                    document.querySelector('#timer-overlay span').innerHTML = --countdown;

                    if (countdown === 0) {
                        clearInterval(countdownInterval);
                        this.takePhoto();
                        document.getElementById('timer-overlay').style.display = 'none';
                        document.querySelector('#timer-overlay span').innerHTML = 5;
                        this.setState({isCountdown: false})
                    }
                }, 1000);
            });

        }
    }

    takePhoto = () => {
        var shutter = document.getElementById('shutter');
        shutter.volume = 0.1;
        shutter.play();

        let dataUri = this.webcam.getScreenshot();

        if (!dataUri) {
            dataUri = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
        }
        if (dataUri !== 'data:,') {

            let fotoCount = this.state.fotoCount;

            fotoCount++;
            this.setState({...this.setState, fotoCount, preview: true, curImg: dataUri}, () => {
                this.updatePerc();
            });

        }
    }


    onPhotoDelete = () => {

        let fotoCount = this.state.fotoCount;

        fotoCount--;
        this.setState({...this.setState, fotoCount, preview: false, curImg: ''}, () => {
            this.updatePerc();
        });
        setTimeout(() => {
            this.setState({...this.state, dialogInfo: false});
        }, 500)

    }

    async onPhotoSave() {

        this.setState({loading: true});
        try {
            const response = await fetch(authconf.adminurl, {
                method: 'post',
                mode: 'cors',
                body: JSON.stringify({
                    base64: this.state.curImg,
                    auth0id: this.user.sub,
                    accessToken: localStorage.getItem('access_token')
                })
            });

            const json = await response.json();

            if (json.success) {
                this.user = JSON.parse(localStorage.getItem('user'));
                if (this.user) {
                    await localStorage.setItem(this.user.sub + '-medel-fotoupload' + ('_' + localStorage.getItem('med-curEar')), this.state.fotoCount);
                    await localStorage.setItem(this.user.sub + '-medel-img-' + this.state.fotoCount + ('_' + localStorage.getItem('med-curEar')), json.path);
                    this.setState({loading: false});
                }
            } else {
                this.onPhotoDelete();
            }

        } catch (error) {
            this.setState({loading: false});
        }


        if (this.state.fotoCount === this.state.totalFotos) {

            document.querySelector('.photoupload.started').classList.remove('started');
            this.setState({
                ...this.setState,
                fotoCount: this.state.fotoCount,
                preview: false,
                curImg: '',
                finishedTest: parseInt(Date.now() / 1000)
            }, async () => {
                await localStorage.setItem(this.user.sub + '-medel-fotoupload-log' + ('_' + localStorage.getItem('med-curEar')), JSON.stringify({
                    patient_start_test: this.state.startTest,
                    patient_end_test: parseInt(Date.now() / 1000)
                }));
                let resp = await interimSubmit();

                this.updatePerc();
                redirectToHome();
            });
        } else {
            this.setState({...this.setState, fotoCount: this.state.fotoCount, preview: false, curImg: ''}, () => {
                this.updatePerc();
            });

        }

        setTimeout(() => {
            this.setState({...this.state, dialogInfo: true});
        }, 500)


    }


    setRef = webcam => {
        this.webcam = webcam;
    };


    onCameraError(error) {
        console.error('onCameraError', error);
    }

    onCameraStart() {

        if (this.state.isLoading === true) {
            this.setState({...this.state, isLoading: false});
        }
    }

    onCameraStop() {
    }


    componentDidMount() {


        auth.checkAccessToken();
        document.getElementById('percentage').style.width = 0;
    }

    render() {
        const {classes, t} = this.props;
        this.boxClass = ["test-content"];
        if (this.state.startTest) {
            this.boxClass.push('start-test');
        }

        if (this.state.finishedTest) {
            this.boxClass.push('finish-test');
        }

        this.boxClass = this.boxClass.join(' ');

        const videoConstraints = {
            width: 1920,
            height: 1080,
            facingMode: this.state.facingMode
        };

        const cam = (this.state.changing || this.state.startTest === false) ? false : <Webcam audio={false}
                                                                                              height={1080}
                                                                                              ref={this.setRef}
                                                                                              onUserMedia={() =>     this.state.cameraInfo && this.setState({...this.state, cameraInfo: false})}
                                                                                              onUserMediaError={() =>     !this.state.cameraInfo && this.setState({...this.state, cameraInfo: true})}
                                                                                              screenshotFormat="image/jpeg"
                                                                                              width={1920}
                                                                                              screenshotQuality={1}
                                                                                              minScreenshotHeight={1920}
                                                                                              minScreenshotWidth={1080}
                                                                                              videoConstraints={videoConstraints}/>;

        let dialog = false;
        switch (this.state.fotoCount) {

            case 0:

                dialog = <Dialog
                    open={this.state.dialogInfo && !this.state.cameraInfo}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleCloseInfo}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title">
                        <Trans i18nKey="earSelfie.procedureInfoWithProcessorAndMagnetTitle"
                               currentSide={localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1') : t('earSelfie.rightSide1')}>
                            Das <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1') : t('earSelfie.rightSide1')}}</strong> Ohr
                            mit Prozessor.
                        </Trans>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <img alt='' src="/img/ohrselfie1.svg?v=2" style={{
                                display: 'block',
                                margin: '45px auto',
                                width: 'auto',
                                height: 'calc(var(--vh,1vh) * 38)',
                                maxWidth: 'none'
                            }}/>
                            <Trans i18nKey="earSelfie.procedureInfoWithProcessorAndMagnet"
                                   currentSide={localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1').toUpperCase() : t('earSelfie.rightSide1').toUpperCase()}>
                                Das <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1').toUpperCase() : t('earSelfie.rightSide1').toUpperCase()}}</strong> Ohr
                                mit Prozessor.
                            </Trans>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{textAlign: 'center', justifyContent: 'center'}}>
                        <Button onClick={this.handleCloseInfo} color="primary">
                            {t('OK')}
                        </Button>
                    </DialogActions>

                </Dialog>;

                break;

            case 1:
                dialog = <Dialog
                    open={this.state.dialogInfo && !this.state.cameraInfo}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleCloseInfo}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">

                    <DialogTitle id="alert-dialog-slide-title">
                        <Trans i18nKey="earSelfie.procedureInfoWithoutProcessorAndMagnetTitle"
                               currentSide={localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1') : t('earSelfie.rightSide1')}>
                            Das <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1') : t('earSelfie.rightSide1')}}</strong> Ohr
                            mit Prozessor.
                        </Trans>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <img alt='' src="/img/ohrselfie2.svg?v=2" style={{
                                display: 'block',
                                margin: '45px auto',
                                width: 'auto',
                                height: 'calc(var(--vh,1vh) * 38)',
                                maxWidth: 'none'
                            }}/>
                            <Trans i18nKey="earSelfie.procedureInfoWithoutProcessorAndMagnet"
                                   currentSide={localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1').toUpperCase() : t('earSelfie.rightSide1').toUpperCase()}>
                                Das <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1').toUpperCase() : t('earSelfie.rightSide1').toUpperCase()}}</strong> Ohr
                                mit Prozessor.
                            </Trans>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{textAlign: 'center', justifyContent: 'center'}}>
                        <Button onClick={this.handleCloseInfo} color="primary">
                            {t('OK')}
                        </Button>
                    </DialogActions>

                </Dialog>;

                break;
            case 2:
                dialog = <Dialog
                    open={this.state.dialogInfo && !this.state.cameraInfo}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleCloseInfo}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title">
                        <Trans i18nKey="earSelfie.procedureInfoOnlyOuterEarTitle"
                               currentSide={localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1') : t('earSelfie.rightSide1')}>
                            Das <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1') : t('earSelfie.rightSide1')}}</strong> Ohr
                            mit Prozessor.
                        </Trans>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <img alt='' src="/img/ohrselfie3.svg" style={{
                                display: 'block',
                                margin: '45px auto',
                                width: 'auto',
                                height: 'calc(var(--vh,1vh) * 38)',
                                maxWidth: 'none'
                            }}/>
                            <Trans i18nKey="earSelfie.procedureInfoOnlyOuterEar"
                                   currentSide={localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1').toUpperCase() : t('earSelfie.rightSide1').toUpperCase()}>
                                Das <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1').toUpperCase() : t('earSelfie.rightSide1').toUpperCase()}}</strong> Ohr
                                mit Prozessor.
                            </Trans>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{textAlign: 'center', justifyContent: 'center'}}>
                        <Button onClick={this.handleCloseInfo} color="primary">
                            {t('OK')}
                        </Button>
                    </DialogActions>
                </Dialog>;

                break;

            default:
                dialog = false;

        }
        let dialogTimer = <Dialog
            open={this.state.timerInfo}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleCloseTimer}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="alert-dialog-slide-title">
                <Trans i18nKey="earSelfie.procedureInfoSelftimerTitle">
                </Trans>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <img alt='' src="/img/selfietimer.svg?v=2" style={{
                        display: 'block',
                        margin: '45px auto',
                        width: 'auto',
                        height: 'calc(var(--vh,1vh) * 38)',
                        maxWidth: 'none'
                    }}/>
                    <Trans i18nKey="earSelfie.procedureInfoSelftimerText">
                    </Trans>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{textAlign: 'center', justifyContent: 'center'}}>
                <Button onClick={this.handleCloseTimer} color="primary">
                    {t('OK')}
                </Button>
            </DialogActions>

        </Dialog>;
        let dialogCameraAccess = <Dialog
            open={this.state.cameraInfo}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-slide-title2"
            aria-describedby="alert-dialog-slide-description2">
            <DialogTitle id="alert-dialog-slide-title2">
                <Trans i18nKey="earSelfie.procedureInfoCameraAccessTitle">
                </Trans>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description2">
                    <img alt='' src="/img/attention.svg?v=2" style={{
                        display: 'block',
                        margin: '10px auto',
                        width: 'auto',
                        height: 'calc(var(--vh,1vh) * 30)',
                        maxWidth: 'none'
                    }}/>
                    <Trans i18nKey="earSelfie.procedureInfoCameraAccessText">
                    </Trans>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{textAlign: 'center', justifyContent: 'center'}}>
                <Button onClick={() => window.location.reload()} color="primary">
                    {t('earSelfie.procedureInfoCameraAccessReload')}
                </Button>
            </DialogActions>
        </Dialog>;
        return (
            <div
                className={(this.state.startTest && !this.state.finishedTest) ? 'lingtest started photoupload' : 'lingtest photoupload'}>
                <Container
                    className={this.state.loading === true ? 'load-overlay loading' : 'load-overlay not-loading'}>
                    <div className={'load-circle'}>
                        <CircularProgress/>
                    </div>
                </Container>)
                <div className={classes.root}>
                    <ElevationScroll {...this.props}>


                        <AppBar color="default">
                            <Toolbar>
                                <Typography variant="h6" className={classes.title}
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                flexGrow: 1,
                                                textAlign: 'left',
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}>{t('earSelfie.title')}</Typography>
                                <IconButton data-closetext={t('closeLabel')} className={'right-button close-icon left'}
                                            edge="end" color="primary" aria-label="menu"
                                            onClick={() => {
                                                if (this.state.startTest) {
                                                    this.setState({...this.state, setOpen: true});
                                                } else {
                                                    window.location.href = '/'
                                                }
                                            }}>

                                    <CloseIcon></CloseIcon>
                                </IconButton>
                            </Toolbar>
                            <div id={'percentage'} className={'percentage-bar'}></div>
                        </AppBar>

                    </ElevationScroll>
                    <Box className={this.boxClass} style={{paddingTop: 90}}>
                        <Container maxWidth='sm'>
                            <div className={'top'}>

                                <Typography variant="body1" className={'gray-box'} style={{marginBottom: 40}}>
                                    <strong>{t('earSelfie.preparation')}</strong>
                                    <div style={{marginTop: 16}}>
                                        <ul>
                                            <li><Trans i18nKey="earSelfie.letSomeoneTakeTheFoto">Test <strong>strong</strong> test</Trans></li>
                                            <li> <Trans i18nKey="earSelfie.letSomeoneTakeTheFotoInfo">Test <strong>strong</strong> test
                                            </Trans><br/>
                                                <mLink onClick={() => this.handleClickOpen()} style={{
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                    marginTop: 10,
                                                    fontWeight: 600,
                                                    display: 'inline-block'
                                                }}>{t('earSelfie.letSomeoneTakeTheFotoInfoLink')}</mLink>
                                            </li>
                                        </ul>
                                    </div>
                                </Typography>

                                <Typography variant="body1" style={{marginBottom: 40}}>
                                    <strong>{t('earSelfie.procedure')}</strong><br/>
                                    <p><Trans i18nKey="earSelfie.procedureInfo1"
                                              side={localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide') : t('earSelfie.rightSide')}>Fotografieren
                                        Sie bitte die Stelle hinter
                                        dem <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide2').toUpperCase() : t('earSelfie.rightSide2').toUpperCase()}}</strong> Ohr</Trans>
                                    </p>
                                    <ol style={{paddingLeft: 25, marginBottom: 30}}>
                                        <li><Trans i18nKey="earSelfie.procedureInfoWithProcessorAndMagnet"
                                                   currentSide={localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1').toUpperCase() : t('earSelfie.rightSide1').toUpperCase()}>
                                            Das <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1').toUpperCase() : t('earSelfie.rightSide1').toUpperCase()}}</strong> Ohr
                                            mit Prozessor.
                                        </Trans></li>
                                        <li><Trans i18nKey="earSelfie.procedureInfoWithoutProcessorAndMagnet"
                                                   currentSide={localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide').toUpperCase() : t('earSelfie.rightSide').toUpperCase()}>
                                            Das <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide').toUpperCase() : t('earSelfie.rightSide').toUpperCase()}}</strong> Ohr
                                            mit Prozessor.
                                        </Trans></li>
                                        <li><Trans i18nKey="earSelfie.procedureInfoOnlyOuterEar"
                                                   currentSide={localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1').toUpperCase() : t('earSelfie.rightSide1').toUpperCase()}>
                                            Das <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1').toUpperCase() : t('earSelfie.rightSide1').toUpperCase()}}</strong> Ohr
                                            mit Prozessor.
                                        </Trans></li>

                                    </ol>
                                </Typography>

                            </div>


                            <div className={this.state.isLoading ? 'bottom' : 'bottom loaded'}>
                                <CircularProgress className={classes.progress}/>
                                <Button variant="contained" color="primary" onClick={() => {
                                    this.setState({...this.state, startTest: parseInt(Date.now() / 1000)});

                                    setTimeout(() => {
                                        this.setState({...this.state, dialogInfo: true});
                                    }, 500)

                                }}>
                                    Start
                                </Button>
                            </div>

                            <div className={this.state.preview ? 'camera-wrap preview' : 'camera-wrap'}>
                                <IconButton className='timer-icon' edge="start" color='secondary' aria-label="menu"
                                            onClick={() => {
                                                this.setState({
                                                    timerOn: !this.state.timerOn,
                                                    timerInfo: this.state.firstTimerInfo,
                                                });
                                            }}>
                                    {this.state.timerOn ? <TimerIcon></TimerIcon> : <TimerOffIcon></TimerOffIcon>}
                                </IconButton>

                                <div className="react-html5-camera-photo react-html5-camera-photo-fullscreen">
                                    <div id="white-flash" className="normal"></div>
                                    <div id="timer-overlay" className="normal"><span>5</span></div>
                                    {cam}
                                    <Link id="container-circles" component="div" onClick={this.onTakePhoto}>
                                        <div id="outer-circle">
                                            <div id="inner-circle" className=""></div>
                                        </div>
                                    </Link></div>
                                <div className={'preview-window'}>
                                    <img src={this.state.curImg} alt={'preview'}/>

                                    <div className={'btn-wrap'}>
                                        <Button onClick={this.onPhotoDelete} color="secondary">
                                            <svg width="42px" height="50px" viewBox="0 0 42 50" version="1.1">
                                                <g stroke="none" fill="none">
                                                    <g transform="translate(-85.000000, -584.000000)" fill="#FFFFFF">
                                                        <g transform="translate(85.000000, 584.000000)">
                                                            <rect x="4" y="12" width="34" height="38"></rect>
                                                            <rect x="0" y="3" width="42" height="5"></rect>
                                                            <rect x="16" y="0" width="10" height="5"></rect>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </Button>
                                        <Button onClick={this.onPhotoSave.bind(this)} color="secondary">

                                            <svg width="64px" height="48px" viewBox="0 0 64 48" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none">
                                                    <g transform="translate(-244.000000, -586.000000)" fill="#FFFFFF">
                                                        <g transform="translate(248.000000, 590.000000)">
                                                            <polygon
                                                                points="19.4782609 29.2014992 3.48758914 13.3617718 -3.48758914 20.4034199 19.4782609 43.1524831 59.4875891 3.52082405 52.5124109 -3.52082405"></polygon>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </Button>
                                    </div>
                                </div>

                                <IconButton className='switch-icon' edge="start" color='secondary' aria-label="menu"
                                            onClick={() => {
                                                this.setState({
                                                    facingMode: this.state.facingMode === 'environment' ? 'user' : 'environment',
                                                    changing: true
                                                }, () => {
                                                    window.setTimeout(() => {
                                                        this.setState({changing: false});
                                                    }, 100)
                                                });
                                            }}>
                                    <SwitchCameraOutlinedIcon></SwitchCameraOutlinedIcon>
                                </IconButton>
                            </div>


                            <div className={'success-message'}>
                                <div className={'header'}>
                                    <div>
                                        <Typography variant="h4">
                                            {t('test')}
                                            <br/>
                                            {t('completed')}
                                        </Typography>
                                        <div className={'arrow'}>
                                            <svg viewBox="0 0 136.3 103" width="136px" height="103px">
                                                <polyline points="10.7,44.6 50.9,84.5 125.6,10.5 "></polyline>
                                                <polyline points="11.6,45.6 50.9,84.5 124.6,11.5 "></polyline>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={'footer'}>
                                    <Button color="primary" onClick={() => {
                                        window.location.href = '/'
                                    }}>
                                        {t('overviewButton')}
                                    </Button>
                                </div>
                            </div>

                        </Container>
                    </Box>
                </div>

                {dialog}
                {dialogTimer}
                {dialogCameraAccess}

                {this.state.startTest ? <Dialog
                        open={this.state.setOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">{t('testPaused')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {t('testPausedInfoMessage')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose}>
                                {t('back')}
                            </Button>
                            <Button onClick={() => {
                                this.handleClose();
                                window.location.href = '/dashboard';
                            }} color="primary" variant="contained">
                                {t('abortTest')}
                            </Button>
                        </DialogActions>

                    </Dialog>
                    : <Dialog
                        open={this.state.setOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description">
                        <div className={'video-content portrait'}>
                            {this.state.setOpen ? <video controls={true} muted={true} autoPlay={true} playsInline={true}
                                                         src={video1}></video> : ''}
                        </div>

                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                {t('close')}
                            </Button>
                        </DialogActions>

                    </Dialog>}

                <audio src={soundfile} id={'shutter'}/>
                <audio src={soundfile2} id={'beep'}/>
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles)(PhotoUpload));
