import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom'
import BackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import "p5/lib/addons/p5.sound";
import CircularProgress from '@material-ui/core/CircularProgress';
import {interimSubmit, redirectToHome} from "../components/Helpers";
import Auth from "../components/Auth";
import {withTranslation, Trans} from 'react-i18next';
import huelse from "../images/AudioLinkConnected.png";
import moment from "moment";
import p5 from 'p5';
import 'p5/lib/addons/p5.sound';


const auth = new Auth();
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textAlign: 'center'
    },
    space: {
        padding: theme.spacing(1.5, 3),
    },

    marginL: {
        marginRight: theme.spacing(1),
    },
});


function ElevationScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};


class SoundCheck2 extends Component {

    constructor(props) {
        super(props);

        const {t} = this.props;

        this.questions = [
            {
                index: 1,
                setVolume: false
            },
            {
                index: 2,
                setVolume: false
            },
            {
                index: 3,
                setVolume: false
            },
            {
                index: 4,
                setVolume: false
            },
            {
                index: 5,
                setVolume: false
            },
            {
                index: 6,
                setVolume: true
            },
        ];

        this.boxShadow = '0 0 0 %%%px rgba(198, 12, 48, 0.1)'
        this.baseVolume = 60;

        this.index = 0;
        this.successsound = false;
        this.unmount = false;
        this.answerGiven = 0;
        this.user = JSON.parse(localStorage.getItem('user'));
        this.state = {
            amplitude: 0,
            disabled: false,
            resArray: {},
            startTest: false,
            curValue: false,
            curType: false,
            curIndex: 1,
            totalQuestions: this.questions.length,
            finishedTest: false,
            percentage: 0,
            isLoading: true,
            curBoxShadow: '',
            buttonClass: 'play',
            dialogInfo: false,
            curVolume: localStorage.getItem(this.user.sub + '-medel-volume' + ('_' + localStorage.getItem('med-curEar'))) ? localStorage.getItem(this.user.sub + '-medel-volume' + ('_' + localStorage.getItem('med-curEar'))) : this.baseVolume,
            volume: localStorage.getItem(this.user.sub + '-medel-volume' + ('_' + localStorage.getItem('med-curEar'))),
        };

        this.sketch = (p) => {
            let rotation = 0;
            let index = 0;
            p.preload = () => {
                this.samplesound = p.loadSound('/sounds/referenceSignal.mp4?v=' + Date.now());
                this.loopsound = p.loadSound('/sounds/volumesetuploop.wav?v=' + Date.now());

            };
        }


    }

    calcBoxShadow = () => {
        let boxShadowArray = [];

        for (var i = 1; i <= parseInt(this.state && this.state.curVolume ? this.state.curVolume : this.baseVolume) / 10; i++) {
            boxShadowArray.push(this.boxShadow.replace('%%%', i * 10));
        }
        return boxShadowArray.join(',');
    }

    updatePerc = async () => {
        let perc = this.state.curIndex / this.state.totalQuestions;
        let perc2 = (this.state.curIndex - 1) / this.state.totalQuestions;
        document.getElementById('percentage').style.width = ((100 * (perc2))) + '%';
        this.state.percentage = Math.round(perc * 100);
        this.setState({percentage: this.state.percentage});


        if (this.state.percentage > 100) {
            this.state.resArray.patient_end_test = parseInt(Date.now() / 1000);
            this.state.resArray.patient_start_test = this.state.startTest;

            this.user = JSON.parse(localStorage.getItem('user'));

            if (this.user) {

                await localStorage.removeItem(this.user.sub + '-medel-ling' + ('_' + localStorage.getItem('med-curEar')));
                await localStorage.removeItem(this.user.sub + '-medel-duotone' + ('_' + localStorage.getItem('med-curEar')));
                await localStorage.removeItem(this.user.sub + '-medel-tripple' + ('_' + localStorage.getItem('med-curEar')));
                await localStorage.setItem(this.user.sub + '-medel-volume' + ('_' + localStorage.getItem('med-curEar')), this.state.curVolume);
                await localStorage.setItem(this.user.sub + '-medel-volume-patient_end_test' + ('_' + localStorage.getItem('med-curEar')), parseInt(Date.now() / 1000));
                await localStorage.setItem(this.user.sub + '-medel-volume-patient_start_test' + ('_' + localStorage.getItem('med-curEar')), this.state.startTest);

                let resp = await interimSubmit();
                this.setState({...this.state, finishedTest: true}, redirectToHome);
                this.handleClose();
            }

        }
    }

    componentWillUnmount() {

    }

    handleNext = () => {
        if (this.samplesound.isPlaying()) {
            this.samplesound.stop();
        }

        if (this.loopsound.isPlaying() && this.state.curIndex === 5) {
            this.loopsound.stop();
        }
        if (this.state.curIndex < 6 || (this.state.curIndex === 6 && !this.state.disabled)) {

            let curQuestion = '';
            this.questions.map((item, key) => {
                if (this.state.curIndex === item.index) {
                    curQuestion = item.title;
                }
                return true
            });


            let curValue = this.state.curValue;


            this.state.curIndex++;

            this.setState({
                    ...this.state,
                    disabled: this.state.curIndex === 6 ? true : false,
                    curIndex: this.state.curIndex,
                    buttonClass: 'play',
                    curValue: this.state.resArray['_' + this.state.curIndex] ? this.state.resArray['_' + this.state.curIndex].value : false
                },
                this.updatePerc
            )
        } else {
            this.setState({dialogInfo: true})
        }


    };

    handleClick = (event) => {

        var value = event.target.value.split('|');

        this.setState({...this.state, curValue: value[1], curVar: event.target.value, curType: value[0]}, () => {
        });

    };

    handleClickOpen = () => {
        this.setState({...this.state, setOpen: true});
    }

    handleClose = () => {
        this.setState({...this.state, setOpen: false});
    }


    startCountdown = (cb) => {

        let counter = 5;
        document.getElementById('countdownbignumber').innerHTML = counter;
        document.getElementById('countdownbig').style.strokeDashoffset = 630;
        this.timeout = setTimeout(() => {
            this.interval = setInterval(() => {
                counter -= 0.025;

                document.getElementById('countdownbignumber').innerHTML = Math.round(counter);
                document.getElementById('countdownbig').style.strokeDashoffset = 630 - (630 - (630 * (counter / 5)));


                if (counter <= 0) {
                    clearInterval(this.interval);

                    if (cb) {
                        cb();
                    }
                }
            }, 25);
        }, 500)
    };

    handleVolumeButtonClick = (type) => {
        switch (type) {
            case "minus":
                if (this.state.curVolume > 10) {
                    this.setState({curVolume: parseInt(this.state.curVolume) - 10}, () => {
                        let newState = {curBoxShadow: this.calcBoxShadow()};
                        this.setState(newState)

                        this.samplesound.setVolume(this.state.curVolume / 100);

                    })
                }
                break;
            case "plus":
                if (this.state.curVolume < 100) {
                    this.setState({curVolume: parseInt(this.state.curVolume) + 10}, () => {
                        let newState = {curBoxShadow: this.calcBoxShadow()};

                        this.setState(newState);

                        this.samplesound.setVolume(this.state.curVolume / 100);

                    })
                }
                break;
        }
    }

    playLoopSound = () =>{
        if (!this.loopsound.isPlaying()) {
            this.loopsound.setVolume(this.state.curVolume / 100);
            this.loopsound.pan( localStorage.getItem('med-curEar') === 'left' ? -1.0 : 1.0);
            this.loopsound.loop();
        }
    }
    handlePlayButtonClick = () => {
        if (this.loopsound.isPlaying()) {
            this.loopsound.stop();
        }
        if (this.state.buttonClass === 'play') {
            if (!this.samplesound.isPlaying()) {
                this.samplesound.setVolume(this.state.curVolume / 100);
                this.samplesound.pan( localStorage.getItem('med-curEar') === 'left' ? -1.0 : 1.0);
                this.samplesound.loop();
            }
            let newState = {buttonClass: 'pause', curBoxShadow: this.calcBoxShadow(), disabled: false};
            this.setState(newState);

        } else {
            if (this.samplesound.isPlaying()) {
                this.samplesound.stop();
            }
            let newState = {buttonClass: 'play', curBoxShadow: this.calcBoxShadow()};
            this.setState(newState);
        }
    }

    componentDidMount() {

        new p5(this.sketch);

        document.getElementById('percentage').style.width = '0%';

        this.updatePerc();
        auth.checkAccessToken();


    }

    render() {
        const {classes, t} = this.props;
        this.boxClass = ["test-content"];
        if (this.state.startTest) {
            this.boxClass.push('start-test');
        }

        if (this.state.finishedTest) {
            this.boxClass.push('finish-test');
        }

        this.boxClass = this.boxClass.join(' ');

        let duedate = parseInt(localStorage.getItem('med-dueDate'));
        duedate = moment(duedate).format(t('dateformat'));
        let intro, bottom = ''
        if (this.state.volume) {
            intro = (<div className={'top'} style={{textAlign: 'center'}}>
                <div className={'header'}>
                    <Typography variant="h4" gutterBottom>{t('setVolume.screens.screenAlreadySet.title')}</Typography>
                </div>
                <img alt=''
                    src={t('setVolume.screens.screenAlreadySet.image').replace('%%SIDE%%', localStorage.getItem('med-curEar') === 'left' ? 'right' : 'left')}/>

                <Typography variant="body1" style={{marginBottom: 15}}>

                    <Trans i18nKey={'setVolume.screens.screenAlreadySet.text'}
                           otherSide={localStorage.getItem('med-curEar') === 'left' ? t('setVolume.leftSide').toUpperCase() : t('setVolume.rightSide').toUpperCase()}
                           currentSide={localStorage.getItem('med-curEar') === 'left' ? t('setVolume.leftSide').toUpperCase() : t('setVolume.rightSide').toUpperCase()}>
                        <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('setVolume.leftSide').toUpperCase() : t('setVolume.rightSide').toUpperCase()}}</strong>
                        <strong>{{otherSide: localStorage.getItem('med-curEar') === 'left' ? t('setVolume.rightSide').toUpperCase() : t('setVolume.leftSide').toUpperCase()}}</strong>
                        <strong></strong>
                        <strong></strong>
                    </Trans>
                </Typography>
                <a  href="javascript:void(0);" style={{marginBottom: 50, display: 'inline-block'}} onClick={() => {
                    this.playLoopSound();
                    this.setState({...this.state, startTest: parseInt(Date.now() / 1000)}, ()=>{

                    });

                }}>{t('setVolume.screens.screenAlreadySet.linktext')}</a>
            </div>)

            bottom = (<div className={'bottom loaded'}><CircularProgress className={classes.progress}/>
                <Button color="primary" onClick={() => {
                    window.location.href = '/';
                }} style={{backgroundColor: '#C60C30'}}>
                    {t('setVolume.screens.screenAlreadySet.cancel')}
                </Button></div>)
        } else {
            bottom = (<div className={'bottom loaded'}><CircularProgress className={classes.progress}/>
                <Button color="primary" onClick={() => {
                    this.playLoopSound();
                    this.setState({...this.state, startTest: parseInt(Date.now() / 1000)});

                }}>
                    {t('setVolume.buttonLabel')}
                </Button></div>)
            intro = (<div className={'top'} style={{textAlign: 'center'}}>
                <div className={'header'}>
                    <Typography variant="h4" gutterBottom>{t('setVolume.header')}</Typography>
                </div>
                <Typography variant="body1" style={{marginBottom: 40}}>
                    {t('setVolume.body')}
                </Typography>
                {(t('setVolume.noticeTitle').trim() && t('setVolume.noticeTitle').trim() !== 'setVolume.noticeTitle') &&
                    <Typography variant="body1" className={'gray-box'} style={{marginBottom: 40, marginTop: 20}}>
                        <strong>{t('setVolume.noticeTitle')}</strong>
                        <div style={{marginTop: 16}}>

                                {(t('setVolume.noticeItem1').trim() && t('setVolume.noticeItem1').trim() !== 'setVolume.noticeItem1') && <span>{t('setVolume.noticeItem1')}</span>}


                        </div>
                    </Typography>}
            </div>)
        }

        return (
            <div
                className={(this.state.startTest && !this.state.finishedTest) ? 'lingtest survey started' : 'lingtest survey'}>
                <div className={classes.root}>
                    <ElevationScroll {...this.props}>


                        <AppBar color="default">
                            <Toolbar>
                                <Typography variant="h6" className={classes.title}
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                flexGrow: 1,
                                                textAlign: 'left',
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}>{t('setVolume.title')}</Typography>
                                <IconButton data-closetext={t('closeLabel')} className={'right-button close-icon left'}
                                            edge="end" color="primary" aria-label="menu"
                                            onClick={() => {
                                                if (this.state.startTest && !this.state.finishedTest) {
                                                    if (this.loopsound.isPlaying()) {
                                                        this.loopsound.stop();
                                                    }
                                                    this.setState({
                                                        ...this.state,
                                                        curIndex: 1,
                                                        curValue: false,
                                                        resArray: {},
                                                        startTest: false
                                                    });
                                                    document.getElementById('percentage').style.width = 0;
                                                } else {
                                                    window.location.href = '/'
                                                }
                                            }}>

                                    <CloseIcon></CloseIcon>
                                </IconButton>
                            </Toolbar>
                            <div id={'percentage'} className={'percentage-bar'}></div>
                        </AppBar>
                    </ElevationScroll>
                    <Toolbar/>
                    <Box className={this.boxClass}>
                        <Container maxWidth='sm'>
                            {intro}

                            <div className={'testMain prep'}>
                                <div className={'questions prep'}>
                                    {this.questions.map((item, key) => {
                                        return (
                                            <div
                                                className={('screen-wrap-' + item.index)+ ' ' + (this.state.curIndex === item.index ? 'question active ' + (this.state.curIndex === 6 ? 'volume' : '') : 'question ')}
                                                data-index={item.index}><span>{t('setVolume.screens.screen' + item.index + '.title')}</span>

                                                {!item.setVolume && <div className={'img-wrap'}>
                                                    <div className="pulsating-circle"></div><img
                                                    src={t('setVolume.screens.screen' + item.index + '.image').replace('%%SIDE%%', localStorage.getItem('med-curEar') === 'left' ? 'right' : 'left')}/></div>}
                                                {item.setVolume && <div className={'setvolume-outer'}>
                                                    <div className={'play-pause-button ' + this.state.buttonClass}
                                                         style={{boxShadow: this.state.curBoxShadow}}
                                                         onClick={this.handlePlayButtonClick}>
                                                        <div></div>
                                                        <div onClick={() => {
                                                            this.handleVolumeButtonClick('plus')
                                                        }}></div>
                                                    </div>
                                                    <div onClick={() => {
                                                        this.handleVolumeButtonClick('minus')
                                                    }} className={'minus'}></div>
                                                    <div onClick={() => {
                                                        this.handleVolumeButtonClick('plus')
                                                    }} className={'plus'}></div>
                                                </div>}
                                                <p>
                                                    <Trans i18nKey={'setVolume.screens.screen' + item.index + '.text'}
                                                           otherSide={localStorage.getItem('med-curEar') === 'left' ? t('setVolume.leftSide').toUpperCase() : t('setVolume.rightSide').toUpperCase()}
                                                           currentSide={localStorage.getItem('med-curEar') === 'left' ? t('setVolume.leftSide').toUpperCase() : t('setVolume.rightSide').toUpperCase()}>
                                                        <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('setVolume.leftSide').toUpperCase() : t('setVolume.rightSide').toUpperCase()}}</strong>
                                                        <strong>{{otherSide: localStorage.getItem('med-curEar') === 'left' ? t('setVolume.rightSide').toUpperCase() : t('setVolume.leftSide').toUpperCase()}}</strong>
                                                        <strong></strong>
                                                        <strong></strong>
                                                    </Trans>
                                                </p>
                                                {(t('setVolume.screens.screen' + item.index + '.linktext').trim() && t('setVolume.screens.screen' + item.index + '.link').trim()) && (
                                                    <a href={t('setVolume.screens.screen' + item.index + '.link').trim()}
                                                       target={'_blank'}>{t('setVolume.screens.screen' + item.index + '.linktext').trim()}</a>)}
                                                {(t('setVolume.screens.screen' + item.index + '.noticeTitle').trim() && t('setVolume.screens.screen' + item.index + '.noticeTitle').trim() !== 'setVolume.screens.screen' + item.index + '.noticeTitle') &&
                                                    <Typography variant="body1" className={'gray-box'} style={{marginBottom: 40, marginTop: 20}}>
                                                        <strong>{t('setVolume.screens.screen' + item.index + '.noticeTitle')}</strong>
                                                        <div style={{marginTop: 16}}>
                                                            {(t('setVolume.screens.screen' + item.index + '.noticeItem').trim() && t('setVolume.screens.screen' + item.index + '.noticeItem').trim() !== 'setVolume.screens.screen' + item.index + '.noticeItem') && <span>{t('setVolume.screens.screen' + item.index + '.noticeItem')}</span> }
                                                            <ul>
                                                                {(t('setVolume.screens.screen' + item.index + '.noticeItem1').trim() && t('setVolume.screens.screen' + item.index + '.noticeItem1').trim() !== 'setVolume.screens.screen' + item.index + '.noticeItem1') && <li>{t('setVolume.screens.screen' + item.index + '.noticeItem1')}</li> }
                                                                {(t('setVolume.screens.screen' + item.index + '.noticeItem2').trim() && t('setVolume.screens.screen' + item.index + '.noticeItem2').trim() !== 'setVolume.screens.screen' + item.index + '.noticeItem2') && <li>{t('setVolume.screens.screen' + item.index + '.noticeItem2')}</li> }
                                                                {(t('setVolume.screens.screen' + item.index + '.noticeItem3').trim() && t('setVolume.screens.screen' + item.index + '.noticeItem3').trim() !== 'setVolume.screens.screen' + item.index + '.noticeItem3') && <li>{t('setVolume.screens.screen' + item.index + '.noticeItem3')}</li> }
                                                                {(t('setVolume.screens.screen' + item.index + '.noticeItem4').trim() && t('setVolume.screens.screen' + item.index + '.noticeItem4').trim() !== 'setVolume.screens.screen' + item.index + '.noticeItem4') && <li>{t('setVolume.screens.screen' + item.index + '.noticeItem4')}</li> }
                                                            </ul>
                                                        </div>
                                                    </Typography>}
                                            </div>


                                        )
                                    })}
                                </div>
                            </div>


                            {bottom}

                            <div className={'testKeyboard survey prep'}>
                                <div className={'arrows small'}>
                                    <Button className={'prev'}
                                            onClick={() => {
                                                if (this.samplesound.isPlaying()) {
                                                    this.samplesound.stop();
                                                }
                                                if (!this.loopsound.isPlaying()) {
                                                    this.playLoopSound();
                                                }

                                                let curQuestion = '';

                                                if (this.state.curIndex === 1) {
                                                    if (this.loopsound.isPlaying()) {
                                                        this.loopsound.stop();
                                                    }
                                                    this.setState({
                                                        ...this.state,
                                                        curIndex: 1,
                                                        curValue: false,
                                                        resArray: {},
                                                        startTest: false,
                                                        buttonClass: 'play',
                                                        disabled: false,
                                                    });
                                                    document.getElementById('percentage').style.width = 0;

                                                } else {
                                                    this.questions.map((item, key) => {
                                                        if (this.state.curIndex === item.index) {
                                                            curQuestion = item.title;
                                                        }
                                                        return true
                                                    });


                                                    this.state.curIndex--;
                                                    this.setState({
                                                        ...this.state,
                                                        curIndex: this.state.curIndex,
                                                        curValue: false,
                                                        buttonClass: 'play',
                                                        disabled: false,
                                                    }, () => {
                                                        this.updatePerc();
                                                    })

                                                }


                                            }}>


                                        <svg width="16px" height="26px" viewBox="0 0 16 26" version="1.1">
                                            <g stroke="none" fill="none">
                                                <g transform="translate(-10.000000, -5.000000)" fill="#C60C30">
                                                    <polygon
                                                        points="15.8284271 18 25.4142136 8.41421356 22.5857864 5.58578644 10.1715729 18 22.5857864 30.4142136 25.4142136 27.5857864"></polygon>
                                                </g>
                                            </g>
                                        </svg>
                                        <a href="javascript:void(0);">{t('stepBackLabel')}</a>
                                    </Button>
                                    <Button className={'next' + (this.state.disabled ? ' disabled' : '')}
                                            onClick={this.handleNext}>


                                        <Button color="primary">{t('nextLabel')}</Button>
                                    </Button>
                                </div>
                            </div>


                            <div className={'success-message'}>
                                <div className={'header'}>
                                    <div>
                                        <Typography variant="h4">
                                            {t('setVolume.successTitle')}
                                        </Typography>
                                        <div className={'arrow'}>
                                            <svg viewBox="0 0 136.3 103"  width="136px" height="103px">
                                                <polyline points="10.7,44.6 50.9,84.5 125.6,10.5 "></polyline>
                                                <polyline points="11.6,45.6 50.9,84.5 124.6,11.5 "></polyline>
                                            </svg>
                                        </div>
                                        <p><Trans i18nKey={'setVolume.successText'}
                                                  otherSide={localStorage.getItem('med-curEar') === 'left' ? t('setVolume.leftSide').toUpperCase() : t('setVolume.rightSide').toUpperCase()}
                                                  currentSide={localStorage.getItem('med-curEar') === 'left' ? t('setVolume.leftSide').toUpperCase() : t('setVolume.rightSide').toUpperCase()}>
                                            <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('setVolume.leftSide').toUpperCase() : t('setVolume.rightSide').toUpperCase()}}</strong>
                                            <strong>{{otherSide: localStorage.getItem('med-curEar') === 'left' ? t('setVolume.rightSide').toUpperCase() : t('setVolume.leftSide').toUpperCase()}}</strong>
                                            <strong></strong>
                                            <strong></strong>
                                        </Trans></p>

                                    </div>
                                </div>
                                <div className={'footer'}>
                                    <Button color="primary" onClick={() => {
                                        window.location.href = '/'
                                    }}>
                                        {t('overviewButton')}
                                    </Button>
                                </div>
                            </div>

                        </Container>
                    </Box>
                </div>
                <Dialog
                    open={this.state.dialogInfo}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => {this.setState({dialogInfo: false})}}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">

                    <DialogTitle id="alert-dialog-slide-title">
                        <Trans i18nKey="setVolume.dialogPressPlay.title"
                               currentSide={localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1'): t('earSelfie.rightSide1')}>
                            Das <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1'): t('earSelfie.rightSide1')}}</strong> Ohr
                            mit Prozessor.
                        </Trans>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <img alt='' src={t('setVolume.dialogPressPlay.image')} style={{display:'block', margin: '45px auto', width: 'auto', height:'calc(var(--vh,1vh) * 30)', maxWidth: 'none'}} />
                            <Trans i18nKey="setVolume.dialogPressPlay.text"
                                   currentSide={localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1').toUpperCase() : t('earSelfie.rightSide1').toUpperCase()}>
                                Das <strong>{{currentSide: localStorage.getItem('med-curEar') === 'left' ? t('earSelfie.leftSide1').toUpperCase() : t('earSelfie.rightSide1').toUpperCase()}}</strong> Ohr
                                mit Prozessor.
                            </Trans>
                            {(t('setVolume.dialogPressPlay.noticeTitle').trim() && t('setVolume.dialogPressPlay.noticeTitle').trim() !== 'setVolume.dialogPressPlay.noticeTitle') &&
                                <Typography variant="body1" className={'gray-box'} style={{marginBottom: 40, marginTop: 20}}>
                                    <strong>{t('setVolume.dialogPressPlay.noticeTitle')}</strong>
                                    <div style={{marginTop: 16}}>
                                        <span>{t('setVolume.dialogPressPlay.noticeItem')}</span>
                                    </div>
                                </Typography>}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{textAlign: 'center', justifyContent: 'center'}}>
                        <Button onClick={() => {this.setState({dialogInfo: false})}} color="primary" >
                            {t('setVolume.dialogPressPlay.linktext')}
                        </Button>
                    </DialogActions>

                </Dialog>
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles)(SoundCheck2));
