import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Link} from 'react-router-dom'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    space: {
        padding: theme.spacing(1.5, 3),
        boxShadow: 'none',
        position: 'relative',
    },

    marginL: {
        marginRight: 12,
        width: 32,
        height: 32
    },
});

class MedelPaper extends Component {


    render() {
        const {classes} = this.props;


        this.control =
            this.props.active ?
                <svg className={classes.marginL}  width="30px" height="30px" viewBox="0 0 30 30">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-10.000000, -11.000000)">
                            <g transform="translate(10.000000, 11.000000)">
                                <circle fill="#FFFFFF" cx="15" cy="15" r="15"></circle>
                                <polyline stroke="#000000" stroke-width="3" points="7 16.4166667 11.7058824 21 23 10"></polyline>
                            </g>
                        </g>
                    </g>
                </svg>:
                <svg className={classes.marginL}  width="30px" height="30px" viewBox="0 0 30 30">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-10.000000, -11.000000)">
                            <g transform="translate(10.000000, 11.000000)">
                                <circle fill="#FFFFFF" cx="15" cy="15" r="15"></circle>
                            </g>
                        </g>
                    </g>
                </svg>;


        return (
            <Link to={this.props.link} style={{textDecoration: 'none'}} className={'MedelPaper ' + (this.props.hide ? 'disabled' : '') + ' ' + (this.props.active ? 'active' : '')}>
                <Paper className={classes.space} square style={{backgroundColor: 'transparent'}}>
                    <FormControlLabel
                        control={this.control}
                        label={<Typography variant="h6" style={{fontWeight: 600}}>
                            {this.props.title}
                        </Typography>}

                    />
                    <span className={'info'}>
                    {this.props.info}
                            </span>
                </Paper>
            </Link>)
    }
}

export default withStyles(styles)(MedelPaper);
