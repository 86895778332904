
import React, {Component } from 'react';
import { withTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';



export const GetTwoDigitLanguageCode = (language) => {           
    // language might be e.g. de-AT but we only need de.                   
    language = language.split('-')[0];      
    return language;
}

export const GetCurrentLanguageCodeOrDefault = (i18n) => {   
    let currentLanguage =  'en-GB';
    // the current auto detected language in i18n might not be supported, so fall back to default.
    if(window._remotecheck_config.languages.indexOf(i18n.language) >= 0) {
        currentLanguage = i18n.language;
    }
    
    return currentLanguage;
}


// component that renders all supported languages and changes the current selected language.
class LanguageSelectorComponent extends Component {

    constructor(props) {
        super(props);
        const {i18n } = this.props;         
        this.changeLanguage = lng => {
            i18n.changeLanguage(lng);
            localStorage.setItem('ref-lang', lang);
        };

        const queryString = window.location.search;

        const urlParams = new URLSearchParams(queryString);

        const lang = urlParams.get('lang') || localStorage.getItem('i18nextLng');

        if(lang && lang !== i18n.language) {
            i18n.changeLanguage(lang);
            localStorage.setItem('i18nextLng', lang);
        }
    }    

    render() {
        const {i18n , t} = this.props;
        const currentLanguage = GetCurrentLanguageCodeOrDefault(i18n);
        return (
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={currentLanguage}
                renderValue={(value) => GetTwoDigitLanguageCode(value).toUpperCase()}
                onChange={(event) => this.changeLanguage(event.target.value)}
                >
                {window._remotecheck_config.languages.map((value, index) => {
                    return <MenuItem value={value}>{t('languages.' + value)}</MenuItem>
                })}
            </Select>            
        );
    }

}

export const LanguageSelector = withTranslation()(LanguageSelectorComponent);
