import React, { Suspense } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/styles';
import theme from './theme';

import PrivateRoute from './components/PrivateRoute';
import ReactDOM from "react-dom";
import Dashboard from './views/Dashboard';
import Login from './views/Login';
import {BrowserRouter as Router} from "react-router-dom";
import {Route} from 'react-router-dom'
import Lingtest from "./views/Lingtest";
import Soundcheck2 from "./views/SoundCheck2";
import {AnimatedSwitch} from 'react-router-transition';
import TrippleDigitTest from "./views/TrippleDigitTest";
import PhotoUpload from "./views/PhotoUpload";
import Auth from './components/Auth';
import Statustest from "./views/Statustest";
import Preparation from "./views/Preparation";
import Quest2Test from "./views/Quest2Test";
import DuotoneTest from "./views/DuotoneTest";
import VersionScreen from "./views/VersionScreen";
import HelpScreen from "./views/HelpScreen";

import ScrollToTop from "./components/ScrollToTop";
import HttpsRedirect from 'react-https-redirect';
import './i18n';
import CircularProgress from '@material-ui/core/CircularProgress';


const auth = new Auth();

const handleAuthentication = ({location}) => {
    if (/access_token|id_token|error/.test(location.hash)) {
         auth.handleAuthentication();
    }else{
        window.location.href = '/';
    }
}

const bounceTransition = {
    // start in a transparent, upscaled state
    atEnter: {
        opacity: 0,

    },
    // leave in a transparent, downscaled state
    atLeave: {
        opacity: 0

    },
    // and rest at an opaque, normally-scaled state
    atActive: {
        opacity: 1,

    },
};

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
    return {
        opacity: styles.opacity,
        transform: `scale(${styles.scale})`,
    };
}

// wrap the `spring` helper to use a bouncy config
/*function bounce(val) {
    return spring(val, {
        stiffness: 100,
        damping: 40,
    });
}*/

const urlParams = new URLSearchParams(window.location.search);

const token = urlParams.get('token');
const lang = urlParams.get('lang');

if(token){

    localStorage.setItem('medel-token', token);

}

if(lang){
    localStorage.setItem('i18nextLng', lang)
}


const Loader = () => (
    <CircularProgress />
  );

ReactDOM.render(
    <HttpsRedirect>
    <Suspense fallback={<Loader />}>
    <ThemeProvider theme={theme}>
        <Router>
            <ScrollToTop>
            <AnimatedSwitch
                atEnter={bounceTransition.atEnter}
                atLeave={bounceTransition.atLeave}
                atActive={bounceTransition.atActive}
                mapStyles={mapStyles}
                className="route-wrapper"
            >
                <Route path="/login" exact component={Login} />
                <PrivateRoute exact path="/" component={Dashboard}/>

                <PrivateRoute path="/version" exact component={VersionScreen} />
                <PrivateRoute path="/help" exact component={HelpScreen} />
                <PrivateRoute path="/lingtest" exact component={Lingtest} />
                <PrivateRoute path="/duotonetest" exact component={DuotoneTest} />
                <PrivateRoute path="/trippletest" exact component={TrippleDigitTest} />
                <PrivateRoute path="/photoupload" exact component={PhotoUpload} />
                <PrivateRoute path="/statustest" exact component={Statustest} />
                <PrivateRoute path="/preparation" exact component={Preparation} />
                <PrivateRoute path="/volume" exact component={Soundcheck2} />
                <PrivateRoute path="/quest2test" exact component={Quest2Test} />

                <Route path="/dashboard" exact render={(props) => {
                    handleAuthentication(props);
                    return <Dashboard {...props} afterLogin={'false'} auth={auth} />
                }}/>
            </AnimatedSwitch>
            </ScrollToTop>

        </Router>
        <CssBaseline/>
    </ThemeProvider>
    </Suspense>
   </HttpsRedirect>
, document.getElementById("root"));
