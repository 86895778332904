import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import logo from '../images/logo_neu.png';
import auth0 from 'auth0-js';
import authconf from '../auth_config';
import Auth0Lock from 'auth0-lock';
import { LanguageSelector, GetCurrentLanguageCodeOrDefault, GetTwoDigitLanguageCode} from '../components/LanguageSelector'
import {Trans, withTranslation} from 'react-i18next';
import { clearLocalStorage } from '../components/Helpers'
import Auth from '../components/Auth';
import Typography from "@material-ui/core/Typography";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Link from "@material-ui/core/Link";
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({

    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    margin: {
        margin: theme.spacing(1),
    },

    withoutLabel: {
        marginTop: theme.spacing(3),

    },

    textField: {
        flexBasis: 200,
    },

}));

const auth = new Auth();

class LoginComponent extends Component {


    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            password: '',
            email: '',
            weight: '',
            weightRange: '',
            showPassword: false,
            error: false,
            checkedTC: false,
            errormessage: '',
        };



        const {t, i18n} = this.props;



        this.getLock = function () {
            // TODO we need to let the user select his language and country for registration.
            var language = GetCurrentLanguageCodeOrDefault(i18n);
            var twoDigitLanguageCode = GetTwoDigitLanguageCode(language);

            const lock = new Auth0Lock(authconf.clientId, authconf.domain, {
                auth: {
                    redirect: true,
                    redirectUrl: (window.location.protocol + '//' + window.location.host.replace(/\/$/, "")) + authconf.callback,
                    responseType: "token",
                    connectionScopes: {
                        connectionName: [authconf.realm],
                    },
                    sso: false,
                    audience: 'https://api.hearingcenter.medel.com'
                },
                theme: {
                    primaryColor: '#C60C30',
                    logo: (window.location.protocol + '//' + window.location.host.replace(/\/$/, "")) + "/img/my_medel.png"

                },
                allowSignUp: true,
                signUpLink: authconf.signup_url.replace('%LANG%',twoDigitLanguageCode),
                language: twoDigitLanguageCode,
                languageDictionary: {
                    title: ""
                },
                overrides: {
                    __tenant: 'medel',
                    __token_issuer: 'auth.login.medel.com'
                },
                rememberLastLogin: false
            });

            return lock;
        }


        this.auth0 = new auth0.WebAuth({
            domain: authconf.domain,
            clientID: authconf.clientId,
            redirectUri: window.location.protocol + '//' + window.location.host + authconf.callback,
            responseType: 'token id_token', // code
        });


        this.handleCookieClick = (e) => {
            e.preventDefault();
            document.getElementById('ot-sdk-btn').click();

        }

        this.handleCheckboxChange = (e) =>{
            this.setState({checkedTC: e.target.checked})
        }


    }

    componentDidMount() {

        clearLocalStorage();

        document.querySelector('body').classList.add('login');
        this.wrapper = document.querySelector('.login-screen');
        this.prof = localStorage.getItem('med-curProf');


        this.interval = window.setInterval(() => {
            this.user = JSON.parse(localStorage.getItem('user'));

            if (this.user) {
                clearInterval(this.interval);
                window.location.href = '/';
            }
        }, 150);

        this.user = JSON.parse(localStorage.getItem('user'));

        if (this.user) {
            clearInterval(this.interval);
            window.location.href = '/';
        }

      //  this.autologin();
    }

    autologin() {
        const lock = this.getLock();                
        lock.checkSession({}, function(err, authResult) { 
            try {
                if(err || !authResult || !authResult.accessToken) {
                    // user not alreadz signed in, do nothing
                    return;
                }                
                
                // in componentDidMount we have a settimeout checking for the user and redirecting..
                auth.setSession(authResult);      
            } catch(e) {
            }
        });
    }   

    login(username, password) {
        this.auth0.login({
            realm: authconf.realm,
            username,
            password
        }, (err, authResult) => {


            if (err) {
                this.wrapper.classList.add('error');
                this.setState({...this.state, error: true, errormessage: err.description});

                setTimeout(
                    function () {
                        this.wrapper.classList.remove('error');
                    }
                        .bind(this),
                    850
                );
                return;
            }
            if (authResult && authResult.idToken && authResult.accessToken) {
                this.setToken(authResult.accessToken, authResult.idToken);

                return;
            }
        })
    }

    handleClick() {
        const lock = this.getLock();
        lock.show();
    }

    render() {

        const {t} = this.props;

        return (
            <Box className="login-screen" fixed='true'>
                <div className={'bg'}></div>

                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{paddingTop: 30, paddingRight: 30}}
                >
                    <div style={{textAlign: 'right'}}>
                    <a className={'logo'} href='/' style={{width: "152px", height: "auto", zIndex: "1", marginLeft: "15px"}}><img
                        alt={t('logo.altText')} src={logo} style={{width: "100%", height: "auto"}}/></a>
                    <LanguageSelector/>
                    </div>
                </Grid>


                <Container className={'login-form'} maxWidth="xs" >
                    <div style={{textAlign: "center", padding: 25}}>
                    <div>{t('login.welcomeTopline')}</div>
                    <Typography variant="h4" gutterBottom style={{marginBottom: 25}}>{t('login.welcomeTitle')}</Typography>
                        <FormGroup row style={{marginBottom:25}}>
                            <FormControlLabel style={{textAlign: 'left', alignItems: 'flex-start', marginBottom: 10}}
                                control={<Checkbox checked={this.state.checkedTC}  color="primary" onChange={this.handleCheckboxChange} name="checkedTC" inputProps={{ 'aria-label': 'primary checkbox' }} />}
                                label={<div><Trans i18nKey="login.tcText"
                                              currentSide={t('login.tcText')}>
                                    Das <a href={t('termsLink')} target={'_blank'}></a>
                                    mit Prozessor.
                                </Trans> <Trans i18nKey="login.privacyText">
                                    Das <a href={t('privacyLink')} target={'_blank'}></a>
                                    mit Prozessor.
                                </Trans></div>}
                            />
                        </FormGroup>
                    <Button variant="contained" color="primary"   disabled={!this.state.checkedTC} className={'red'} onClick={() => this.handleClick()}>
                        {t('login.loginButtonText')}
                    </Button>
                    </div>
                </Container>
            </Box>
        );
    }
};

const Login = withTranslation()(LoginComponent);

export default Login;
