import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#C60C30',
    },
    secondary: {
      main: '#f0f0f0',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },

  },
  overrides: {
    MuiTypography: {
      h4:{
        fontSize: '1.9rem'
      },
      body1: {
        fontWeight: 400,
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#FFF',
        fontSize: 20,
        backgroundColor: '#009aa6',
        letterSpacing: '-0.01em',
        fontWeight: 600,
        padding: '13px 18px',
        lineHeight: 1.366,
        borderRadius: '2px',
        boxShadow:'none',
        '&:hover': {
          backgroundColor: '#009aa6',
        }
      },
      containedPrimary: {
        color: '#FFF',
        fontSize: 20,
        backgroundColor: '#009aa6',
        letterSpacing: '-0.01em',
        fontWeight: 600,
        padding: '13px 18px',
        lineHeight: 1.366,
        borderRadius: '2px',
        boxShadow:'none',
        '&:hover': {
          backgroundColor: '#009aa6',
        }
      }
    }
  }
});

export default theme;
