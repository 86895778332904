import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import logo from '../images/logo_neu.png';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import {Redirect} from 'react-router-dom'
import Auth from '../components/Auth';
import authconf from '../auth_config';
import {clearLocalStorage} from "../components/Helpers";
import StarRatings from 'react-star-ratings';
import {withTranslation} from 'react-i18next';
import {LanguageSelector} from '../components/LanguageSelector'
import moment from 'moment'
import DialogContentText from "@material-ui/core/DialogContentText";

const auth = new Auth();


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    space: {
        padding: theme.spacing(1.5, 3),
    },

    marginL: {
        marginRight: theme.spacing(1),
    },
});


function ElevationScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

class HelpScreen extends Component {

    constructor(props) {
        super(props);


        this.state = {
            lingtest_left: false,
            duotonetest_left: false,
            trippletest_left: false,
            statuscheck_left: false,
            volume_left: false,
            rating: 0,
            lingtest_right: false,
            duotonetest_right: false,
            trippletest_right: false,
            statuscheck_right: false,
            volume_right: false,
            quest2test: false,
            all_left: false,
            all_right: false,
            all: false,
            textAreaValue: '',
            fotoupload: 0,
            name: false,
            left: false,
            logout: false,
            setOpen: false,
            setOpenInfo: localStorage.getItem('med-hide-notifictation') || localStorage.getItem('med-cancel-test') ? false : true,
            setOpenMessage: false,
            setOpenMessageCancel: localStorage.getItem('med-cancel-test') ? true : false,
            allFinished: false,
            transmitted: false,
            loaded: false,
            test: true,
            daysLeft: 0,
            leftEar: parseInt(localStorage.getItem('med-leftEar')) === 1,
            rightEar: parseInt(localStorage.getItem('med-rightEar')) === 1,
            curEar: localStorage.getItem('med-curEar') ? localStorage.getItem('med-curEar') : 'right',
            curName: (localStorage.getItem('med-curFirst') ? localStorage.getItem('med-curFirst') : '') + (localStorage.getItem('med-curLast') ? (localStorage.getItem('med-curFirst') ? ' ' : '') + localStorage.getItem('med-curLast') : ''),

        }


    }


    msToDays(ms) {
        return Math.floor(ms / (24 * 60 * 60 * 1000));
    }

    handleClickOpen = () => {
        this.setState({...this.state, setOpen: true});
    };

    handleClose = () => {
        localStorage.setItem('med-hide-notifictation', true);
        this.setState({...this.state, setOpen: false, setOpenInfo: false});
    };


    handleCookieClick = (e) => {
        e.preventDefault();
        document.getElementById('ot-sdk-btn').click();
    }

    async authuser() {


        try {
            const data = {
                action: 'authuser',
                medremtoken: localStorage.getItem('medel-token') ?? null,
                auth0id: this.user.sub ?? null,
                accessToken: localStorage.getItem('access_token') ?? null,
                timestamp: parseInt(Date.now() / 1000),
            }
            const response = await fetch(authconf.adminurl,
                {
                    method: "POST",
                    mode: 'cors',
                    body: JSON.stringify(data)
                }
            );

            if (json.test === 'yes') {
                let left = false;
                let right = false;
                localStorage.removeItem('med-leftEar');
                localStorage.removeItem('med-rightEar');

                for (let i = 0; i < json.side.length; i++) {

                    if (json.side[i] === 'left') {
                        left = true;
                        localStorage.setItem('med-leftEar', 1)
                    }
                    if (json.side[i] === 'right') {
                        right = true;
                        localStorage.setItem('med-rightEar', 1)

                    }
                }


                if (!localStorage.getItem('med-curEar')) localStorage.setItem('med-curEar', json.side[0]);


                if (json.firstname && json.firstname.length)
                    localStorage.setItem('med-curFirst', json.firstname);


                if (json.lastname && json.lastname.length)
                    localStorage.setItem('med-curLast', json.lastname);


                if (localStorage.getItem('medel-token')) {
                    localStorage.removeItem('medel-token');
                }


                if (typeof json.interim === 'object' && json.interim !== null) {
                    await this.restoreTest(json.interim);
                }

                if (typeof json.professional === 'object' && json.professional !== null) {
                    localStorage.setItem('med-curProf', JSON.stringify(json.professional));
                }


                this.setState({
                    test: (json.test === 'yes'),
                    duedate: parseInt(json.duedate) * 1000,
                    daysLeft: (parseInt(json.duedate) * 1000) - moment() + (24 * 60 * 60 * 1000),
                    curCheckup: json.curCheckup,
                    leftEar: left,
                    rightEar: right,
                    pasttest: json.pasttest,
                    professional: typeof json.professional === 'object' && json.professional !== null ? json.professional : null,
                    curName: ((json.firstname ? json.firstname : '') + (json.lastname ? (json.firstname ? ' ' : '') + json.lastname : '')),
                    curEar: localStorage.getItem('med-curEar')
                }, () => {

                });


            } else {
                this.setState({
                    test: false,
                });
            }


        } catch (error) {
            this.setState({
                test: false,
            });
        }


        return;

    }

    async componentDidMount() {


        auth.checkAccessToken();
        document.querySelector('body').classList.remove('login');
        this.user = JSON.parse(localStorage.getItem('user'));

        if (this.user) {
            this.user.name = (this.user && this.user.user_metadata && this.user.user_metadata.first_name) ? ((this.user.user_metadata.first_name ? this.user.user_metadata.first_name : '') + (this.user.user_metadata.last_name ? (this.user.user_metadata.first_name ? ' ' : '') + this.user.user_metadata.last_name : '')) : this.user.name;

            await this.authuser();
            this.setState({name: this.user.name});
            clearInterval(this.interval);
            this.setState({
                lingtest_left: localStorage.getItem(this.user.sub + '-medel-ling_left') ? true : false,
                duotonetest_left: localStorage.getItem(this.user.sub + '-medel-duotone_left') ? true : false,
                trippletest_left: localStorage.getItem(this.user.sub + '-medel-tripple_left') ? true : false,
                statuscheck_left: localStorage.getItem(this.user.sub + '-medel-statuscheck_left') ? true : false,
                volume_left: localStorage.getItem(this.user.sub + '-medel-volume_left') ? true : false,
                quest2test: localStorage.getItem(this.user.sub + '-medel-quest2') ? true : false,
                fotoupload_left: parseInt(localStorage.getItem(this.user.sub + '-medel-fotoupload_left')) === 3 ? 3 : 0,
                lingtest_right: localStorage.getItem(this.user.sub + '-medel-ling_right') ? true : false,
                duotonetest_right: localStorage.getItem(this.user.sub + '-medel-duotone_right') ? true : false,
                trippletest_right: localStorage.getItem(this.user.sub + '-medel-tripple_right') ? true : false,
                statuscheck_right: localStorage.getItem(this.user.sub + '-medel-statuscheck_right') ? true : false,
                volume_right: localStorage.getItem(this.user.sub + '-medel-volume_right') ? true : false,
                fotoupload_right: parseInt(localStorage.getItem(this.user.sub + '-medel-fotoupload_right')) === 3 ? 3 : 0
            }, () => {
                if ((this.state.lingtest_left && this.state.duotonetest_left && this.state.trippletest_left && this.state.volume_left && this.state.quest2test && this.state.fotoupload_left) || this.state.leftEar === false) {


                    this.setState({all_left: true}, () => {

                    });
                }
                if ((this.state.lingtest_right && this.state.duotonetest_right && this.state.trippletest_right && this.state.statuscheck_right && this.state.quest2test && this.state.fotoupload_right) || this.state.rightEar === false) {

                    this.setState({all_right: true}, () => {

                        setTimeout(() => {
                            if (this.state.all_right === true && this.state.all_left === true) {
                                this.setState({all: true}, () => {
                                });
                            } else {
                                this.setState({all: false}, () => {
                                });

                            }

                        }, 500)
                    });
                }
            });
        } else {

            this.interval = window.setInterval(async () => {
                this.user = JSON.parse(localStorage.getItem('user'));

                if (this.user) {
                    this.user.name = (this.user && this.user.user_metadata && this.user.user_metadata.first_name) ? ((this.user.user_metadata.first_name ? this.user.user_metadata.first_name : '') + (this.user.user_metadata.last_name ? (this.user.user_metadata.first_name ? ' ' : '') + this.user.user_metadata.last_name : '')) : this.user.name;

                    await this.authuser();
                    this.setState({...this.state, name: this.user.name});


                    clearInterval(this.interval);
                }
            }, 1000);

        }

        this.setState({loaded: true})


    }

    restoreTest = async (interim) => {

        if (interim.left.lingtest) {
            await localStorage.setItem(this.user.sub + '-medel-ling_left', interim.left.lingtest);
        }
        if (interim.left.duotonetest) {
            await localStorage.setItem(this.user.sub + '-medel-duotone_left', interim.left.duotonetest);
        }
        if (interim.left.trippletest) {
            await localStorage.setItem(this.user.sub + '-medel-tripple_left', interim.left.trippletest);
        }
        if (interim.left.statuscheck) {
            await localStorage.setItem(this.user.sub + '-medel-statuscheck_left', interim.left.statuscheck);
        }
        if (interim.left.volume) {
            await localStorage.setItem(this.user.sub + '-medel-volume_left', interim.left.volume);
        }
        if (interim.left.quest2test) {
            await localStorage.setItem(this.user.sub + '-medel-quest2', interim.left.quest2test);
        }

        if (interim.left.fotoupload && interim.left.fotoupload.image1 && interim.left.fotoupload.image2 && interim.left.fotoupload.image3) {
            await localStorage.setItem(this.user.sub + '-medel-fotoupload_left', 3);
            if (interim.left.fotoupload && interim.left.fotoupload.image1) {
                await localStorage.setItem(this.user.sub + '-medel-img-1_left', interim.left.fotoupload.image1);
            }
            if (interim.left.fotoupload && interim.left.fotoupload.image2) {
                await localStorage.setItem(this.user.sub + '-medel-img-2_left', interim.left.fotoupload.image2);
            }
            if (interim.left.fotoupload && interim.left.fotoupload.image3) {
                await localStorage.setItem(this.user.sub + '-medel-img-3_left', interim.left.fotoupload.image3);
            }

            if (interim.left.fotoupload && interim.left.fotoupload.log) {
                await localStorage.setItem(this.user.sub + '-medel-fotoupload-log_left', interim.left.fotoupload.log);
            }
        }


        if (interim.left.fotoupload && interim.left.fotoupload.image1 && interim.left.fotoupload.image2 && interim.left.fotoupload.image3) {
            await localStorage.setItem(this.user.sub + '-medel-fotoupload_left', 3);
        }

        if (interim.right.lingtest) {
            await localStorage.setItem(this.user.sub + '-medel-ling_right', interim.right.lingtest);
        }
        if (interim.right.duotonetest) {
            await localStorage.setItem(this.user.sub + '-medel-duotone_right', interim.right.duotonetest);
        }
        if (interim.right.trippletest) {
            await localStorage.setItem(this.user.sub + '-medel-tripple_right', interim.right.trippletest);
        }
        if (interim.right.statuscheck) {
            await localStorage.setItem(this.user.sub + '-medel-statuscheck_right', interim.right.statuscheck);
        }
        if (interim.right.quest2test) {
            await localStorage.setItem(this.user.sub + '-medel-quest2', interim.right.quest2test);
        }

        if (interim.right.volume) {
            await localStorage.setItem(this.user.sub + '-medel-volume_right', interim.right.volume);
        }

        if (interim.right.fotoupload && interim.right.fotoupload.image1 && interim.right.fotoupload.image2 && interim.right.fotoupload.image3) {
            await localStorage.setItem(this.user.sub + '-medel-fotoupload_right', 3);
            if (interim.right.fotoupload && interim.right.fotoupload.image1) {
                await localStorage.setItem(this.user.sub + '-medel-img-1_right', interim.right.fotoupload.image1);
            }
            if (interim.right.fotoupload && interim.right.fotoupload.image2) {
                await localStorage.setItem(this.user.sub + '-medel-img-2_right', interim.right.fotoupload.image2);
            }
            if (interim.right.fotoupload && interim.right.fotoupload.image3) {
                await localStorage.setItem(this.user.sub + '-medel-img-3_right', interim.right.fotoupload.image3);
            }

            if (interim.right.fotoupload && interim.right.fotoupload.log) {
                await localStorage.setItem(this.user.sub + '-medel-fotoupload-log_right', interim.right.fotoupload.log);
            }
        }


    };

    handleOnTextChange = (event) => {
        this.pMessage = event.target.value;
        this.setState({
            textareaValue: event.target.value
        },)
    };


    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({...this.state, [side]: open});
    };

    sideList = side => {
        const {t} = this.props;
        return (
            <div
                className={'menu'}
                role="presentation"
                onClick={this.toggleDrawer(side, false)}
                onKeyDown={this.toggleDrawer(side, false)}
            >
                <List>
                    <div>
                        <ListItem button key={this.props.t('dashboardMenu.overview')} onClick={() => {
                            window.location.href = '/';
                        }}>
                            <ListItemText primary={this.props.t('dashboardMenu.overview')}/>
                        </ListItem>
                        <Divider/>
                    </div>
                    <div>
                        <ListItem button key={this.props.t('dashboardMenu.help')} onClick={() => {
                            window.location.href = '/help';
                        }}>
                            <ListItemText className={'active-menu'} primary={this.props.t('dashboardMenu.help')}/>
                        </ListItem>
                        <Divider/>
                    </div>
                    <div>
                        <ListItem button key={this.props.t('dashboardMenu.version')} onClick={() => {
                            window.location.href = '/version';
                        }}>
                            <ListItemText  primary={this.props.t('dashboardMenu.version')}/>
                        </ListItem>
                        <Divider/>
                    </div>
                    <div>
                        <ListItem button key={this.props.t('dashboardMenu.logout')} onClick={() => {
                            auth.logout();
                        }}>
                            <ListItemText primary={this.props.t('dashboardMenu.logout')}/>
                        </ListItem>
                        <Divider/>
                    </div>

                </List>
                <div className={'bottom'}>
                    <a href={t('termsLink')} target={'_blank'} style={{display :'block'}}>{t('termsLinktext')}</a>



                    <a href={t('privacyLink')} target={'_blank'} style={{display :'block' , marginBottom: 20}}>{t('privacyLinktext')}</a>
                    <strong><a onClick={this.handleCookieClick} href={'#'}
                                                                                     className={'NavLink cookieref'}>{this.props.t('dashboardMenu.cookieSettings')}</a>
                    </strong>
                </div>
            </div>
        )
    };


    render() {
        const {classes, t} = this.props;


        if (this.state.logout === true) {
            return (<Redirect to={{pathname: '/login', state: {from: '/dashboard '}}}/>)
        } else {
            return (
                <div className={classes.root}>
                    <AppBar color="default" fixed='true'>
                        <Toolbar>
                            <IconButton edge="start" className={classes.menuButton} color="primary"
                                        aria-label="menu" onClick={this.toggleDrawer('left', true)}>
                                <MenuIcon/>
                            </IconButton>
                            <LanguageSelector/>
                            <Typography variant="h6" className={classes.title}>

                            </Typography>

                            <a href='/' className={'logo'} style={{marginLeft: "15px"}}><img
                                alt='' src={logo}/></a>
                        </Toolbar>
                    </AppBar>
                    <Toolbar/>
                    <div className={'intro-content'} style={{marginTop: 20}}>
                        <Container maxWidth='sm'>
                            <div className={'header'}>
                                <Grid container spacing={3}>

                                    <Grid item xs={6}>
                                        <Typography variant="h5" gutterBottom
                                                    style={{}}> {t('helpScreen.title')}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <IconButton className={'menu-close close-icon left'} color="primary"
                                                    aria-label="menu" data-closetext={t('closeLabel')} onClick={() => {
                                            window.location.href = '/';
                                        }}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <div>
                                    <img alt='' src="/img/frage.svg?v=2" style={{
                                        display: 'block',
                                        margin: '45px auto',
                                        width: 'auto',
                                        height: 'calc(var(--vh,1vh) * 12)',
                                        maxWidth: 'none'
                                    }}/>
                                    <Typography variant="h6" gutterBottom
                                                style={{textAlign: 'center'}}>{t('helpScreen.text')}<br />
                                        <a href={t('helpScreen.textLink')} target={'_blank'} style={{marginTop: 20, marginBottom: 40, display :'inline-block'}}>{t('helpScreen.textLinktext')}</a>
                                    </Typography>

                                </div>
                                <div>
                                    <a href={t('termsLink')} target={'_blank'} style={{display :'block'}}>{t('termsLinktext')}</a>



                                    <a href={t('privacyLink')} target={'_blank'} style={{display :'block', marginTop: 10}}>{t('privacyLinktext')}</a>
                                </div>
                                <div style={{clear: 'both'}}></div>

                                <div style={{marginTop: 30}}>
                                </div>

                            </div>
                        </Container>
                    </div>
                    <SwipeableDrawer
                        open={this.state.left}
                        onClose={this.toggleDrawer('left', false)}
                        onOpen={this.toggleDrawer('left', true)}
                    >
                        <IconButton className={'menu-close close-icon '} color="primary"
                                    aria-label="menu" data-closetext={t('closeLabel')} onClick={() => {
                            this.setState({...this.state, left: false});
                        }}>
                            <CloseIcon/>
                        </IconButton>
                        {this.sideList('left')}
                    </SwipeableDrawer>
                </div>

            );
        }
    }
}

export default withTranslation()(withStyles(styles)(HelpScreen));
