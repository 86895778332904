import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close';
import "p5/lib/addons/p5.sound";
import CircularProgress from '@material-ui/core/CircularProgress';
import {interimSubmit, redirectToHome} from "../components/Helpers";
import Auth from "../components/Auth";
import {withTranslation} from 'react-i18next';

const auth = new Auth();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textAlign: 'center'
    },
    space: {
        padding: theme.spacing(1.5, 3),
    },

    marginL: {
        marginRight: theme.spacing(1),
    },
});


function ElevationScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};


class Quest2Test extends Component {

    constructor(props) {
        super(props);

        const {t} = props;

        this.questions = [
            {
                index: 1,
                quest: t('myListeningComprehension.q1'),
                answers: [
                    {text: t('muchBetter'), type: 'proceed', id: 'muchBetter'},
                    {text: t('better'), type: 'proceed', id: 'better'},
                    {text: t('same'), type: 'proceed', id: 'same'},
                    {text: t('worse'), type: 'proceed', id: 'worse'},
                    {text: t('muchWorse'), type: 'proceed', id: 'muchWorse'},
                ]
            },
            {
                index: 2,
                quest: t('myListeningComprehension.q2'),
                answers: [{text: t('yes'), type: 'proceed', id: 'yes'}, {text: t('no'), type: 'proceed', id: 'no'}]
            },
            {
                index: 3,
                quest: t('myListeningComprehension.q3'),
                answers: [{text: t('yes'), type: 'proceed', id: 'yes'}, {text: t('no'), type: 'proceed', id: 'no'}]
            },
            {
                index: 4,
                quest: t('myListeningComprehension.q4'),
                answers: [{text: t('yes'), type: 'proceed', id: 'yes'}, {text: t('no'), type: 'proceed', id: 'no'}]
            },
        ];

        //EDIT HERE
        this.rounds = 3;

        this.index = 0;
        this.successsound = false;
        this.unmount = false;
        this.totalAnswers = this.rounds * 6;
        this.answerGiven = 0;
        this.user = JSON.parse(localStorage.getItem('user'));
        this.state = {
            amplitude: 0,
            disabled: true,
            resArray: {},
            startTest: false,
            curValue: false,
            curIndex: 1,
            totalQuestions: this.questions.length,
            finishedTest: false,
            percentage: 0,
            setOpen: false,
            isLoading: true
        };


    }

    updatePerc = async () => {
        let perc = this.state.curIndex / this.state.totalQuestions;
        let perc2 = (this.state.curIndex - 1) / this.state.totalQuestions;
        document.getElementById('percentage').style.width = ((100 * (perc2))) + '%';
        this.state.percentage = Math.round(perc * 100);
        this.setState({percentage: this.state.percentage});
        if (this.state.percentage > 100) {
            this.state.resArray.patient_end_test = parseInt(Date.now() / 1000);
            this.state.resArray.patient_start_test = this.state.startTest;


            this.user = JSON.parse(localStorage.getItem('user'));

            if (this.user) {
                await localStorage.setItem(this.user.sub + '-medel-quest2', JSON.stringify(this.state.resArray));
                let resp = await interimSubmit();

                this.setState({finishedTest: true}, redirectToHome);
            }
        }
    };

    componentWillUnmount() {

    };


    handleClick = (input) => {
        this.setState({...this.state, curValue: input}, () => {
        });

    };

    handleClickOpen = () => {
        this.setState({...this.state, setOpen: true});


    }

    handleClose = () => {
        this.setState({...this.state, setOpen: false});

    }


    componentDidMount() {


        auth.checkAccessToken();
        document.getElementById('percentage').style.strokeDashoffset = 100;

        this.updatePerc();

    }

    render() {
        const {classes, t} = this.props;
        this.boxClass = ["test-content"];
        if (this.state.startTest) {
            this.boxClass.push('start-test');
        }

        if (this.state.finishedTest) {
            this.boxClass.push('finish-test');
        }

        this.boxClass = this.boxClass.join(' ');

        return (
            <div
                className={(this.state.startTest && !this.state.finishedTest) ? 'lingtest survey started' : 'lingtest survey'}>
                <div className={classes.root}>
                    <ElevationScroll {...this.props}>
                        <AppBar color="default">
                            <Toolbar>
                                <Typography variant="h6" className={classes.title}
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                flexGrow: 1,
                                                textAlign: 'left',
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}>{t('myListeningComprehension.title')}</Typography>
                                <IconButton data-closetext={t('closeLabel')} className={'right-button close-icon left'}
                                            edge="end" color="primary" aria-label="menu"
                                            onClick={() => {
                                                window.location.href = '/'
                                            }}>

                                    <CloseIcon></CloseIcon>
                                </IconButton>
                            </Toolbar>
                            <div id={'percentage'} className={'percentage-bar'}></div>
                        </AppBar>
                    </ElevationScroll>
                    <Toolbar/>
                    <Box className={this.boxClass}>
                        <Container maxWidth='sm'>
                            <div className={'top'} style={{textAlign: 'center'}}>
                                <div className={'header'}>
                                    <img alt='' src={'/img/audioprozessor.svg'}
                                         style={{marginBottom: 30, width: '50%', height: 'auto'}}/>
                                    <Typography variant="h4"
                                                gutterBottom>{t('myListeningComprehension.title')}</Typography>
                                </div>
                                <Typography variant="body1" style={{marginBottom: 40}}>
                                    {t('myListeningComprehension.introMessage')}
                                </Typography>
                            </div>



                            <div className={'bottom loaded'}>
                                <CircularProgress className={classes.progress}/>
                                <Button variant="contained" color="primary" onClick={() => {
                                    this.setState({...this.state, startTest: parseInt(Date.now() / 1000)});

                                }}>
                                    {t('start')}
                                </Button>
                            </div>
                            <div className={'testKeyboard survey quest prep'}>
                                <div>
                                    <div className={'testMain'}>
                                        <div className={'questions'}>
                                            {this.questions.map((item, key) => {
                                                return <div
                                                    className={this.state.curIndex === item.index ? 'question active' : 'question'}
                                                    data-index={item.index}>{item.quest}</div>
                                            })}
                                        </div>
                                    </div>

                                    <div>
                                        {this.questions[this.state.curIndex - 1] ? this.questions[this.state.curIndex - 1].answers.map((item, key) => {
                                            return <Button color="primary" onClick={() => this.handleClick(item.id)}
                                                           className={'question ' + (this.state.curValue == (item.id) ? 'active' : '')}>{item.text}</Button>
                                        }) : false}
                                    </div>
                                </div>
                                <div className={'arrows small border'}>
                                    <Button className={'prev'}
                                            onClick={() => {
                                                if (this.state.curIndex === 1) {
                                                    this.setState({
                                                        ...this.state,
                                                        curIndex: 1,
                                                        curValue: false,
                                                        resArray: {},
                                                        startTest: false
                                                    });
                                                    document.getElementById('percentage').style.width = 0;

                                                } else {
                                                    let curQuestion = '';
                                                    this.questions.map((item, key) => {
                                                        if (this.state.curIndex === item.index) {
                                                            curQuestion = item.quest;
                                                        }
                                                        return true
                                                    });
                                                    this.state.resArray['_' + this.state.curIndex] = {
                                                        index: this.state.curIndex,
                                                        value: this.state.curValue,
                                                        quest: curQuestion,
                                                        type: 'proceed',
                                                    };
                                                    this.setState({
                                                        ...this.state,
                                                        resArray: this.state.resArray
                                                    }, () => {
                                                        this.state.curIndex--;
                                                        this.setState({
                                                            ...this.state,
                                                            curIndex: this.state.curIndex,
                                                            curValue: this.state.resArray['_' + this.state.curIndex] ? this.state.resArray['_' + this.state.curIndex].value : false
                                                        }, () => {
                                                            this.updatePerc();
                                                        })
                                                    });
                                                }
                                            }}>


                                        <svg width="16px" height="26px" viewBox="0 0 16 26" version="1.1">
                                            <g stroke="none" fill="none">
                                                <g transform="translate(-10.000000, -5.000000)" fill="#C60C30">
                                                    <polygon
                                                        points="15.8284271 18 25.4142136 8.41421356 22.5857864 5.58578644 10.1715729 18 22.5857864 30.4142136 25.4142136 27.5857864"></polygon>
                                                </g>
                                            </g>
                                        </svg>

                                        <a href="javascript:void(0);">{t('stepBackLabel')}</a>
                                    </Button>
                                    <Button className={'next'} disabled={this.state.curValue === false ? true : false}
                                            onClick={() => {
                                                let curQuestion = '';
                                                this.questions.map((item, key) => {
                                                    if (this.state.curIndex === item.index) {
                                                        curQuestion = item.quest;
                                                    }
                                                    return true
                                                });
                                                this.state.resArray['_' + this.state.curIndex] = {
                                                    index: this.state.curIndex,
                                                    value: this.state.curValue,
                                                    quest: curQuestion,
                                                };
                                                this.setState({...this.state, resArray: this.state.resArray}, () => {
                                                    this.state.curIndex++;
                                                    this.setState({
                                                        ...this.state,
                                                        curIndex: this.state.curIndex,
                                                        curValue: this.state.resArray['_' + this.state.curIndex] ? this.state.resArray['_' + this.state.curIndex].value : false
                                                    }, () => {
                                                        this.updatePerc();
                                                    })
                                                });
                                            }}>


                                        <Button color="primary">{t('nextLabel')}</Button>
                                    </Button>
                                </div>
                            </div>


                            <div className={'success-message'}>
                                <div className={'header'}>
                                    <div>
                                        <Typography variant="h4">
                                            {t('test')}
                                            <br/>
                                            {t('completed')}
                                        </Typography>
                                        <div className={'arrow'}>
                                            <svg viewBox="0 0 136.3 103" width="136px" height="103px">
                                                <polyline points="10.7,44.6 50.9,84.5 125.6,10.5 "></polyline>
                                                <polyline points="11.6,45.6 50.9,84.5 124.6,11.5 "></polyline>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={'footer'}>
                                    <Button color="primary" onClick={() => {
                                        window.location.href = '/'
                                    }}>
                                        {t('overviewButton')}
                                    </Button>
                                </div>
                            </div>

                        </Container>
                    </Box>
                </div>

                {this.state.startTest ? <Dialog
                        open={this.state.setOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">{t('testPaused')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {t('testPausedInfoMessage')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose}>
                                {t('back')}
                            </Button>
                            <Button onClick={() => {
                                this.handleClose();
                                window.location.href = '/dashboard';
                            }} color="primary" variant="contained">
                                {t('abortTest')}
                            </Button>
                        </DialogActions>

                    </Dialog>
                    : <Dialog
                        open={this.state.setOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">{"Ling Test"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
                                dolor.
                                Aenean massa. Cum sociis natoque penatibus et magnis.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Schließen
                            </Button>
                        </DialogActions>

                    </Dialog>}

            </div>
        );
    }
}

export default withTranslation()(withStyles(styles)(Quest2Test));
