import authconf from "../auth_config";


export function clearLocalStorage() {
  // remember/restore the previously selected language.
  var currentLanguage = localStorage.getItem('i18nextLng');
  var token = localStorage.getItem('medel-token');
  // Clear access token and ID token from local storage
  localStorage.clear();
  localStorage.setItem('i18nextLng', currentLanguage);
  localStorage.setItem('medel-token', token);
}


export function redirectToHome() {
    window.setTimeout(()=>{
  //      window.location.href = "/";
    }, 2000)
}

export async function interimSubmit() {
    let arrayLeft = {}, arrayRight = {};
    const user = JSON.parse(localStorage.getItem('user'));

    if(user) {
        const state = {
            leftEar: parseInt(localStorage.getItem('med-leftEar')) === 1,
            rightEar: parseInt(localStorage.getItem('med-rightEar')) === 1,
        }

        let side = [];


        if (state.leftEar) {

            if (state.leftEar === true) side.push('left');
            arrayLeft = {
                preparation: localStorage.getItem(user.sub + '-medel-preparation'),
                lingtest: localStorage.getItem(user.sub + '-medel-ling_left'),
                duotonetest: localStorage.getItem(user.sub + '-medel-duotone_left'),
                trippletest: localStorage.getItem(user.sub + '-medel-tripple_left'),
                statuscheck: localStorage.getItem(user.sub + '-medel-statuscheck_left'),
                quest2test: localStorage.getItem(user.sub + '-medel-quest2'),
                fotoupload: {
                    image1: localStorage.getItem(user.sub + '-medel-img-1_left'),
                    image2: localStorage.getItem(user.sub + '-medel-img-2_left'),
                    image3: localStorage.getItem(user.sub + '-medel-img-3_left'),
                    log: localStorage.getItem(user.sub + '-medel-fotoupload-log_left')
                },
                volume: localStorage.getItem(user.sub + '-medel-volume_left'),
                volume_patient_end_test: localStorage.getItem(user.sub + '-medel-volume-patient_end_test_left'),
                volume_patient_start_test: localStorage.getItem(user.sub + '-medel-volume-patient_start_test_left'),
            }
        }

        if (state.rightEar) {
            if (state.rightEar === true) side.push('right');
            arrayRight = {
                preparation: localStorage.getItem(user.sub + '-medel-preparation'),
                lingtest: localStorage.getItem(user.sub + '-medel-ling_right'),
                duotonetest: localStorage.getItem(user.sub + '-medel-duotone_right'),
                trippletest: localStorage.getItem(user.sub + '-medel-tripple_right'),
                statuscheck: localStorage.getItem(user.sub + '-medel-statuscheck_right'),
                quest2test: localStorage.getItem(user.sub + '-medel-quest2'),
                fotoupload: {
                    image1: localStorage.getItem(user.sub + '-medel-img-1_right'),
                    image2: localStorage.getItem(user.sub + '-medel-img-2_right'),
                    image3: localStorage.getItem(user.sub + '-medel-img-3_right'),
                    log: localStorage.getItem(user.sub + '-medel-fotoupload-log_right')

                },
                volume: localStorage.getItem(user.sub + '-medel-volume_right'),
                volume_patient_end_test: localStorage.getItem(user.sub + '-medel-volume-patient_end_test_right'),
                volume_patient_start_test: localStorage.getItem(user.sub + '-medel-volume-patient_start_test_right'),
            }
        } else {
            arrayRight = arrayLeft;
        }

        if (!state.leftEar) {
            arrayLeft = arrayRight;
        }



        const finalarray = {
            auth0id: user.sub,
            accessToken: localStorage.getItem('access_token'),
            mode: 'submitTestInterim',
            left: arrayLeft,
            right: arrayRight,
            side: side
        };

        try {
            const response = await fetch(authconf.adminurl, {
                method: 'post',
                mode: 'cors',
                body: JSON.stringify(finalarray)
            });

            const json = await response.json();

            if (json.success) {
                return {success: true}
            }

        } catch (error) {
            return {success: false}

        }
    }else{
        return {success: false}
    }
}
